/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars,  no-undef, max-len, quotes, space-infix-ops, dot-notation, prefer-destructuring, prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Grid, Button, Card, Icon, ContactCard, StampCard, Badge, Page } from 'tabler-react';
import { Icon, Container, Grid, Card, ContactCard, Page } from 'tabler-react';
import {
  Pill,
  DisplayText,
  Heading,
  SectionHeading,
  Subheading,
  ButtonGroup,
  ToggleButton,
  Stack,
  Badge,
  Switch,
  Button,
  IconButton,
  AssetCard,
  Asset,
  Flex,
  Box,
  Text,
  TextLink,
  Collapse,
  Tooltip,
  // Grid,
} from '@contentful/f36-components';
import SiteWrapper from '../../../../wrappers/SiteWrapper';
import OrderTable from '../components/OrderTable';
import providersActions from '../../../../redux/reducers/providers';
import WashnImage from '../../../../helpers/WashnImage';
import MembershipsTable from '../components/MembershipsTable';

function CustomersProfile({ match: { params } }) {
  const { uuid } = params;
  const [total, setTotal] = useState(0);
  const [succesed, setSuccessed] = useState(0);
  const [expired, setExpired] = useState(0);
  const dispatch = useDispatch();
  const provider = useSelector((state) => state.providers.selectedProvider);

  useEffect(() => {
    dispatch(providersActions.getCustomerOrdersByPk(uuid));
  }, [uuid]);
  // console.log();

  const customer = useSelector((state) => state.providers.customerOrders);
  // console.log('BAD', customer[0].user.vehicles_aggregate.aggregate.count);
  // console.log('BAD', customer[0].user.profile.verified_location.lat);

  // console.log('JOSN', customer[0].user.profile.verified_location);
  // console.log('RIGHT', JSON.stringify(customer[0].user.profile.verified_location));

  // const where = JSON.parse(customer[0].user.profile.verified_location);
  // console.log(where);
  // console.log('LEFT', JSON.stringify(customer[0].user.profile.verified_location['position']['lat']));

  // const { position: { lat, lng } } = JSON.parse(customer[0].user.profile.verified_location);
  // console.log(lat, lng);
  // const setCentralLoc = ({ latitude: lat, longitude: lng });
  // console.log(setCentralLoc);

  // const jsonString = JSON.stringify(customer.user.profile.verified_location);
  // console.log('HEY', jsonString);
  // const parsedJson = JSON.parse(jsonString);
  // const lat = parsedJson.position.lat;
  // const lng = parsedJson.position.lng;

  // const locationdata = customer[0].user.profile.verified_location;
  // lat = locationdata[position.lat];
  // console.log(customer[0].user.profile.(Jverified_location);

  // console.log('MEMBERSHIP', customer);
  // console.log('Image', customer);

  const [isExpanded, setIsExpanded] = React.useState(true);

  useEffect(() => {
    let sum = 0;
    let success = 0;
    let expire = 0;
    customer.forEach((item) => {
      sum += item.total;
      if (item.charge_status === 'succeeded') success += 1;
      else expire += 1;
    });
    setTotal(sum);
    setSuccessed(success);
    setExpired(expire);
  }, [customer]);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Stack flexDirection="column">
            <Container>
              <Card>
                {customer.length > 0 && (
                  <Grid.Row cards deck>
                    <Grid.Col md={4}>
                      <Card>
                        <Card.Header>
                          <Card.Title style={{ fontSize: "20px", fontWeight: "bold" }}>
                            Customer Information
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <WashnImage
                            style={{
                              padding: 40,
                              width: "100%",
                              height: "auto",
                              objectFit: "cover",
                            }}
                            src={customer[0].user.profile.picture}
                            alt="Customer Image"
                            skeletonHeigth={250}
                            level="private"
                          />
                        </Card.Body>
                        <Card.Footer style={{ maxHeight: "30%", padding: "20px" }}>
                          <Stack>
                            <Text
                              fontSize="22px"
                              marginTop="10px"
                              style={{ fontWeight: 600 }}
                            >
                             <Icon prefix="fe" name="user" /> {customer[0].user.profile.name} {customer[0].user.profile.lastname}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="18px" marginTop="10px">
                            <Icon prefix="fe" name="map-pin" /> {""} {customer[0].user.profile.address}, {customer[0].user.profile.city}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="18px" marginTop="10px">
                            <Icon prefix="fe" name="map-pin" />{" "}{customer[0].user.profile.state}, {customer[0].user.profile.zip}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="18px" marginTop="10px" style={{ fontWeight: "bold" }}>
                              <Icon prefix="fe" name="phone" />{" "}
                              {customer[0].user.profile.phone_number}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="18px" marginTop="10px">
                              <Icon prefix="fe" name="mail" /> {customer[0].user.email}
                            </Text>
                          </Stack>
                        </Card.Footer>
                      </Card>
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <Card>
                        <Card.Header>
                          <Card.Title>{provider.name} Vehicle Information</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <WashnImage
                            style={{ width: '100%', height: 'auto' }}
                            src={customer[0].user.vehicles[0]?.image}
                            alt="Vehicle Image"
                            skeletonHeigth={250}
                            level="private"
                          />
                        </Card.Body>
                        <Card.Footer>
                          <Stack>
                            <Text fontSize="fontSizeL" marginBottom="spacingXs">
                              Number of vehicles:{' '}
                              {customer[0].user.vehicles_aggregate.aggregate.count}
                              {''}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeL" marginTop="spacingXs">
                              Nickname: {customer[0].user.vehicles[0]?.nickname} {''}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeL" marginTop="spacingXs">
                              Make: {customer[0].user.vehicles[0]?.maker.name}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeL" marginTop="spacingXs">
                              Model: {customer[0].user.vehicles[0]?.model.name}{' '}
                            </Text>
                            <Text fontSize="fontSizeL" marginTop="spacingXs">
                              Category: {customer[0].user.vehicles[0]?.model.category.name}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeL" marginTop="none">
                              Year: {customer[0].user.vehicles[0]?.year}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeM" marginTop="none">
                              Color: {customer[0].user.vehicles[0]?.color}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeM" marginTop="none">
                              State: {customer[0].user.vehicles[0]?.state}
                            </Text>
                          </Stack>
                          <Stack>
                            <Text fontSize="fontSizeM" marginTop="none">
                              Plate#: {customer[0].user.vehicles[0]?.license}
                            </Text>
                          </Stack>
                        </Card.Footer>
                      </Card>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Card>
                        <Card.Header>
                          <Card.Title>{provider.name} Order Summary</Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <SectionHeading marginBottom="spacingXs">Total Orders</SectionHeading>
                          <SectionHeading marginBottom="none">
                            <Icon prefix="fe" name="shopping-bag" color="red" /> {customer.length}
                          </SectionHeading>
                          <SectionHeading marginBottom="spacingXs">
                            <Icon prefix="fe" name="dollar-sign" /> {total.toFixed(2)}
                          </SectionHeading>
                          <SectionHeading marginBottom="spacingXs">
                            <Icon prefix="fe" name="award" /> Membership Orders
                          </SectionHeading>
                          <SectionHeading marginBottom="none">
                            Expired
                            <Icon prefix="fe" name="award" /> {expired}
                          </SectionHeading>
                          <SectionHeading marginBottom="none">
                            <Icon prefix="fe" name="phone" /> {succesed}
                          </SectionHeading>
                        </Card.Body>
                      </Card>
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Card>
            </Container>
            <Container>
              <Grid.Row>
                <Grid.Col>
                  <MembershipsTable />
                </Grid.Col>
              </Grid.Row>
            </Container>
            <Container />
            <Container>
              <OrderTable />
            </Container>
          </Stack>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(CustomersProfile);
