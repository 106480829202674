import { Storage } from 'aws-amplify';

class Images {
  static upload = async (blob, setKey) => {
    const { key } = await Storage.put(`${blob.size}-${setKey}.${blob.type.split('/')[1]}`, blob, {
      contentType: blob.type,
      level: 'public',
    });
    return key;
  };

  static remove = async (key) => Storage.remove(key, { level: 'private' });
}

export default Images;
