import * as React from 'react';

function StandaloneFormPage({ children }) {
  return (
    <div className="page">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div
              className="col col-login mx-auto"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '90vh',
              }}
            >
              <div className="text-center mb-6" />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StandaloneFormPage;
