import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import authCreators, { AuthTypes } from '../reducers/auth';
import profileCreators from '../reducers/profile';
import alertActions from '../reducers/alert';
import loadingActions from '../reducers/loading';
import providersActions from '../reducers/providers';
import safe from './sagaErrorHandling';

export default [
  attachSignIn,
  attachLogout,
  attachSignUp,
  attachForgotPassword,
  attachConfirmForgotPassword,
  attachResetPassword,
  attachWorkerSignup,
  attachCreateProfile,
  attachUpdateProfile,
];

function* attachSignUp() {
  yield takeEvery(AuthTypes.SIGN_UP, safe(signUp));
}

function* attachSignIn() {
  yield takeEvery(AuthTypes.SIGN_IN, safe(signIn));
}

function* attachLogout() {
  yield takeEvery(AuthTypes.LOGOUT, safe(logout));
}

function* attachForgotPassword() {
  yield takeEvery(AuthTypes.FORGOT_PASSWORD, safe(forgotPassword));
}
function* attachConfirmForgotPassword() {
  yield takeEvery(AuthTypes.CONFIRM_FORGOT_PASSWORD, safe(confirmForgotPassword));
}

function* attachResetPassword() {
  yield takeEvery(AuthTypes.RESET_PASSWORD, safe(resetPassword));
}

function* attachWorkerSignup() {
  yield takeEvery(AuthTypes.WORKER_SIGNUP, safe(workerSignup));
}

function* attachCreateProfile() {
  yield takeEvery(AuthTypes.CREATE_PROFILE, safe(createProfile));
}

function* attachUpdateProfile() {
  yield takeEvery(AuthTypes.UPDATE_PROFILE, safe(updateProfile));
}

function* signUp(action) {
  yield put(loadingActions.setLoading(true));
  try {
    const { username, password, callback } = action;
    const authApi = yield getContext('authApi');
    yield call(authApi.signUp, username, password);

    yield put(
      alertActions.displayAlert({
        message: 'Please follow the instructions on the email to verify your account',
        type: 'success',
      }),
    );
    if (callback) callback();
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* signIn(action) {
  yield put(loadingActions.setLoading(true));
  try {
    const { username, password } = action;
    const authApi = yield getContext('authApi');
    const userApi = yield getContext('userApi');
    yield call(authApi.signIn, username, password);

    const userLogged = yield Auth.currentAuthenticatedUser();
    const userRole = yield call(userApi.getRole, userLogged?.username);
    const checkRole = userRole.data.user[0].role;
    if (checkRole !== 'provider' && checkRole !== 'admin') {
      Auth.signOut();
      throw new Error('You do not have access to this website.');
    }
    if (checkRole !== 'admin' && checkRole !== 'provider') {
      Auth.signOut();
      throw new Error('You do not have access to this website.');
    }
    yield put(alertActions.displayAlert({ message: 'Succesfully logged in', type: 'success' }));
    yield put(profileCreators.initProfile());
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* logout() {
  try {
    const authApi = yield getContext('authApi');
    yield call(authApi.logOut);
    yield put(authCreators.setUser({ user: null, authenticated: false }));
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
}

function* forgotPassword({ username, callback }) {
  try {
    const authApi = yield getContext('authApi');
    yield call(authApi.forgotPassword, username);
    yield put(
      alertActions.displayAlert({
        message: 'An email with instructions to reset your password was sent',
        type: 'success',
      }),
    );
    if (callback) callback();
  } catch (err) {
    yield put(
      alertActions.displayAlert({
        message: err.message,
        type: 'error',
      }),
    );
  }
}

function* confirmForgotPassword({ formData, callback }) {
  try {
    const authApi = yield getContext('authApi');
    yield call(authApi.confirmForgotPassword, formData);
    yield put(
      alertActions.displayAlert({
        message: 'Successfully changed password, now please log in',
        type: 'success',
      }),
    );
    if (callback) callback();
  } catch (err) {
    yield put(
      alertActions.displayAlert({
        message: err.message,
        type: 'error',
      }),
    );
  }
}

function* resetPassword({ payload, callback }) {
  yield put(loadingActions.setLoading(true));
  try {
    const { code, email, password } = payload;
    const authApi = yield getContext('authApi');
    yield call(authApi.resetPassword, email, code, password);
    yield put(
      alertActions.displayAlert({
        message: 'Your password has been changed, you may now log in',
        type: 'success',
      }),
    );
    if (callback) callback();
  } catch (err) {
    yield put(
      alertActions.displayAlert({
        message: err.message,
        type: 'error',
      }),
    );
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* workerSignup({ email, providerId, callback }) {
  yield put(loadingActions.setLoading(true));
  try {
    const authApi = yield getContext('authApi');
    yield call(authApi.workerSignup, email, providerId);
    yield put(
      alertActions.displayAlert({
        message: `An email has been sent to: ${email} providing instructions to signup`,
        type: 'success',
      }),
    );
    if (callback) callback();
    yield put(providersActions.getProviderByPk(providerId));
  } catch (err) {
    yield put(
      alertActions.displayAlert({
        message: err.message,
        type: 'error',
      }),
    );
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* createProfile({ payload }) {
  yield put(loadingActions.setLoading(true));
  try {
    const userApi = yield getContext('userApi');
    yield call(userApi.insertProfile, payload);

    yield put(
      alertActions.displayAlert({
        message: 'Profile user data was saved',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* updateProfile({ payload }) {
  yield put(loadingActions.setLoading(true));
  try {
    const userApi = yield getContext('userApi');
    yield call(userApi.updateProfileByPk, payload);

    yield put(
      alertActions.displayAlert({
        message: 'Profile user data was updated',
        type: 'success',
      }),
    );
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}
