import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import '../styles/loading.css';
import washnLogo from '../assets/washn logo.png';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    backgroundColor: '#1D4C6F',
  },
}))(LinearProgress);

function LoadingScreen({ loading }) {
  if (!loading) return null;
  return (
    <div className="screen-container">
      <div className="content-container">
        <img src={washnLogo} alt="Washn Logo" />
        <div>
          <BorderLinearProgress size={60} />
        </div>
      </div>
    </div>
  );
}

export default LoadingScreen;
