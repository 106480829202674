/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify';

const Checkmark = ({ selected }) => (
  <div
    style={
      selected
        ? { right: '30px', top: '4px', position: 'absolute', zIndex: '1' }
        : { display: 'none' }
    }
  >
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
};
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
};
const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: '20px',
};

const SelectedImage = ({
  photo,
  margin,
  direction,
  top,
  left,
  selectable,
  selectAll,
  onChangeImage,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [imagePreview, setImagePreview] = useState(undefined);

  //   calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100;
  const sy = (100 - (30 / photo.height) * 100) / 100;
  // const sx = (100 - (30 / 1) * 100) / 100;
  // const sy = (100 - (30 / 1) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;
  // photo.width = 1;
  // photo.height = 1;

  if (direction === 'column') {
    cont.position = 'absolute';
    cont.left = left;
    cont.top = top;
  }

  useEffect(() => {
    setIsSelected(selectAll);
  }, [selectAll]);

  const handleOnClick = () => {
    if (selectable) {
      onChangeImage(photo, !isSelected);
    }
  };

  useEffect(() => {
    try {
      const { key } = JSON.parse(photo.src);
      // console.log('=============key:', key);
      Storage.get(key, { level: 'public' }).then((data) => setImagePreview(data));
      // console.log('=============imagePreview:', imagePreview);
    } catch (err) {
      console.log(err);
    }
  });

  return (
    <div
      style={{ margin, height: 300, width: 300, ...cont }}
      className={!isSelected ? 'not-selected' : ''}
    >
      <Checkmark selected={isSelected} />
      <label className="text-center form-label"> {photo.title}</label>
      <img
        alt={photo.title}
        style={isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }}
        src={imagePreview}
        onClick={() => handleOnClick()}
      />
      <style>{'.not-selected:hover{outline:2px solid #06befa}'}</style>
    </div>
  );
};

export default SelectedImage;
