/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars, prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, Form, Dropdown } from 'tabler-react';
import {
  Icon,
  Text,
  Card,
  IconButton,
  Button,
  Heading,
  Paragraph,
  Modal,
  TextInput,
  TextLink,
  Badge,
  FormControl,
  Subheading,
  Stack,
  Box,
  Popover,
  Tooltip,
  Accordion,
} from '@contentful/f36-components';
import { Collapse } from 'antd';
import { PreviewIcon } from '@contentful/f36-icons';
import QRCode from 'react-qr-code';
import { TablePagination } from '@material-ui/core/';
import moment from 'moment';

export default function OrderTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [reportsCustom, setReportsCustom] = useState([]);

  const orders = useSelector((state) => state.providers.customerOrders);

  const [search, setSearch] = useState('');
  const [sortedReports, setSortedReports] = useState(reportsCustom);

  const { Panel } = Collapse;

  // Popover
  const [isOpen, setIsOpen] = useState(false);

  // Modal
  const [isShown, setShown] = useState(false);

  useEffect(() => {
    const data = orders.map((item) => ({
      id: item.id,
      name: item.user.profile.name,
      lastname: item.user.profile.lastname,
      email: item.user.email,
      service_name: item.details[0].service?.name,
      addon_name: item.details[0].addon?.name,
      membership_name: item.details[0].membership?.name,
      washn_code: item.washn_code,
      order_date: item.created_at,
      order_status: item.status,
      charge_status: item.charge_status,
      order_number: item.number,
      total: item.total,
    }));
    setReportsCustom(data);
  }, [orders]);

  useEffect(() => {
    if (typeof search !== 'string' || search.length === 0) {
      setSortedReports(reportsCustom);
    }

    const searchLower = search.toLowerCase();
    setSortedReports(
      reportsCustom.filter((report) => {
        if (report.name.toLowerCase().includes(searchLower)) {
          return true;
        }

        if (report.email.toLowerCase().includes(searchLower)) {
          return true;
        }

        return false;
      }),
    );
  }, [reportsCustom, search]);

  if (!orders) return null;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleInputChange = ({ target: { value } }) => {
    setSearch(value);
  };

  return (
    <Card
      className="pt-3"
      title={
        <>
          <Subheading marginTop="spacingS" marginBottom="spacingM" marginLeft="spacingS">
            Order History
          </Subheading>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl className="col-12" marginTop="spacingl" marginBottom="spacingl">
              <TextInput.Group spacing="spacingS">
                <TextInput
                  // icon="search"
                  placeholder="Provider Name or Email"
                  // position="append"
                  onChange={handleInputChange}
                />
                <Dropdown
                  type="button"
                  style={{ backgroundColor: 'red' }}
                  toggle
                  triggerContent="Filter"
                  color="primary"
                  icon="filter"
                  items={[
                    <Dropdown.Item
                      onClick={() =>
                        setSortedReports(reportsCustom.filter((item) => !!item.order_status))
                      }
                    >
                      Successed
                    </Dropdown.Item>,
                    <Dropdown.Item
                      onClick={() =>
                        setSortedReports(reportsCustom.filter((item) => !item.order_status))
                      }
                    >
                      Not Successed
                    </Dropdown.Item>,
                    <Dropdown.ItemDivider />,
                    <Dropdown.Item onClick={() => setSortedReports(reportsCustom)}>
                      Remove Filters
                    </Dropdown.Item>,
                  ]}
                />
              </TextInput.Group>
            </FormControl>
          </div>
        </>
      }
    >
      {orders.length === 0 ? (
        <Text center muted>
          The customer currently has no orders.
        </Text>
      ) : (
        <>
          <Table
            style={{ fontSize: 12 }}
            highlightRowOnHover
            responsive
            className="card-table table-vcenter text-wrap"
            headerItems={[
              { content: '#' },
              { content: 'Order Status' },
              { content: 'WashnCode' },
              { content: 'Order Date' },
              { content: 'Payment Status' },
              { content: 'Service' },
              { content: 'Addon' },
              { content: 'Membership' },
              { content: 'Total' },
            ]}
            bodyItems={sortedReports
              .sort((a, b) => {
                if (a.verified === true && b.verified === false) return 1;
                if (a.verified === false && b.verified === true) return -1;
                return 0;
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                ({
                  id,
                  email,
                  order_number,
                  order_status,
                  washn_code,
                  order_date,
                  charge_status,
                  service_name,
                  addon_name,
                  membership_name,
                  total,
                }) => ({
                  key: id + email,
                  item: [
                    { content: order_number },
                    {
                      content: (
                        <Badge
                          variant={
                            order_status ? 'positive' || 'featured' || 'negative' : 'warning'
                          }
                          className="mr-1"
                        >
                          {order_status}
                        </Badge>
                      ),
                    },
                    {
                      content: (
                        <Collapse
                          accordion bordered={false}
                          // expandIcon=
                          ghost
                          >
                          <Panel
                            className="mr-1"
                            // showArrow={false}
                            header={washn_code}>
                            <QRCode
                              size={80}
                              value={washn_code}
                            />
                            </Panel>
                        </Collapse>
                      ),
                    },
                    // { content: washn_code },
                    {
                      content: moment(new Date(order_date).valueOf()).format('MM/DD/yyyy hh:mm'),
                    },
                    { content: charge_status },
                    { content: service_name },
                    { content: addon_name },
                    { content: membership_name },
                    { content: `$${total.toFixed(2)}` },
                  ],
                }),
              )}
          />
          <TablePagination
            rowsPerPageOptions={[25, 50, 75]}
            component="div"
            count={sortedReports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Rows"
          />
        </>
      )}
    </Card>
  );
}
