import hmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';

const boot = (user) => {
  const bytes = hmacSHA256(user.id, process.env.REACT_APP_INTERCOM_SECRET);
  const hash = Hex.stringify(bytes);

  window.Intercom('boot', {
    api_base: process.env.REACT_APP_INTERCOM_API_BASE,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    user_id: user.id,
    name: user.email,
    email: user.email,
    user_hash: hash,
    created_at: Math.round(new Date().getTime() / 1000), // Unix timestamp
  });
};

export default { boot };
