import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPlayer from 'react-player/youtube';

export default function VideoDialog({ handleClose, open, handleSubmit }) {
  const [input, setInput] = useState('');
  const [errors, setErrors] = useState(undefined);

  useEffect(() => {
    if (!ReactPlayer.canPlay(input) && input.length > 0) {
      setErrors({ message: 'Please enter a valid Youtube URL' });
    } else setErrors(null);
  }, [input]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Modify Video</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To modify this provider location video please enter a valid Youtube URL in this field
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Youtube Link"
          type="url"
          value={input}
          onChange={({ target: { value } }) => setInput(value)}
          fullWidth
          helperText={errors ? errors?.message : null}
          error={!!errors}
        />
        {input.length > 0 && (
          <ReactPlayer
            style={{ maxWidth: '100%', maxHeight: '100%', alignSelf: 'center' }}
            url={input}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit(input)}
          disabled={input.length === 0 || errors?.message}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
