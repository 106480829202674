/* eslint-disable react/no-unknown-property, no-unused-vars */
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useDispatch, useSelector } from 'react-redux';
import amplifyInit from './helpers/amplifyInit';
import 'tabler-react/dist/Tabler.css';
import '@tremor/react/dist/esm/tremor.css';
import Login from './pages/not-authenticated/Login';
import Signup from './pages/not-authenticated/Signup';
import BetaSignup from './pages/not-authenticated/Beta-Signup';
import './styles/app.css';
import profileCreators from './redux/reducers/profile';
import NotFound from './pages/404/404Page';
import ProtectedRoute from './helpers/PrivateRoute';
import AlertState from './helpers/alerts';
import ProvidersHome from './pages/authenticated/providers/ProvidersHome';
import FormDialog from './helpers/formDialog';
import ProvidersProfile from './pages/authenticated/providers/ProvidersProfile';
import ProvidersImages from './pages/authenticated/providers/ProvidersImages';
import ProvidersUpdate from './pages/authenticated/providers/ProvidersUpdate';
import ProvidersCreate from './pages/authenticated/providers/ProvidersCreate';
import ServicesCreateUpdate from './pages/authenticated/providers/services/ServicesCreateUpdate';
import AddonsCreateUpdate from './pages/authenticated/providers/addons/AddonsCreateUpdate';
import LoadingScreen from './helpers/LoadingScreen';
import ProvidersPrivateRoute from './helpers/ProvidersPrivateRoute';
import AdminsPrivateRoute from './helpers/AdminsPrivateRoute';
// import Beta from './pages/not-authenticated/Beta';
import WorkersCreateUpdate from './pages/authenticated/providers/workers/WorkersCreateUpdate';
import MembershipCreateUpdate from './pages/authenticated/providers/memberships/MembershipCreateUpdate';
import AccountConfirmation from './pages/not-authenticated/AccountConfirmation';
import ResetPassword from './pages/not-authenticated/ResetPassword';
import ProvidersReport from './pages/authenticated/providers/ProvidersReport';
import Customers from './pages/authenticated/providers/customers/Customers';
import CustomersProfile from './pages/authenticated/providers/customers/CustomersProfile';
import IntercomManager from './helpers/intercomManager';
import ProviderUserData from './pages/authenticated/providers/ProviderUserData';
import AdminApps from './pages/authenticated/providers/AdminApps';
import Products from './pages/authenticated/providers/Products';

// import AdminApps from ',/pages/authenticated/providers/AdminApps';
import Landing from './pages/not-authenticated/Landing';

Sentry.init({
  dsn: 'https://dfc2adbf4c7b463db830445840c69154@o490177.ingest.sentry.io/5606875',
  environment: process.env.REACT_APP_ENVIRONMENT,

  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

amplifyInit();

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileCreators.initProfile());
  }, []);

  const { authenticated, user } = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.loading);
  const isAuthenticated = authenticated;

  useEffect(() => {
    if (user) {
      IntercomManager.boot(user);
    }
  }, [user]);
  // console.log(user);

  useEffect(() => {
    if (authenticated) dispatch(profileCreators.getHereApiKey());
  }, [authenticated]);
  if (isAuthenticated === null) return null;

  return (
    <>
      <LoadingScreen loading={loading} />
      <AlertState />
      <FormDialog />
      <Router>
        <Switch>
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            path="/providers"
            exact
            Component={ProvidersHome}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            path="/reports"
            exact
            Component={ProvidersReport}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            exact
            path="/providers/profile/:uuid"
            Component={ProvidersProfile}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            // user={user}
            exact
            path="/providers/products"
            Component={Products}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            exact
            path="/providers/customers/:uuid"
            Component={Customers}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            exact
            path="/providers/customer/:uuid"
            Component={CustomersProfile}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/create"
            Component={ProvidersCreate}
          />
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/images"
            Component={ProvidersImages}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/update/:uuid"
            Component={ProvidersUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/userdata"
            Component={ProviderUserData}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/adminapps/"
            Component={AdminApps}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/services/create"
            Component={ServicesCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/services/update/:uuid"
            Component={ServicesCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/addons/create"
            Component={AddonsCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/addons/update/:uuid"
            Component={AddonsCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/workers/create/"
            Component={WorkersCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/workers/update/:uuid"
            Component={WorkersCreateUpdate}
          />
          <ProvidersPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/workers/update/:uuid"
            Component={WorkersCreateUpdate}
          />
          <AdminsPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/memberships/create/"
            Component={MembershipCreateUpdate}
          />
          <AdminsPrivateRoute
            isAuthenticated={isAuthenticated}
            user={user}
            exact
            path="/providers/memberships/update/:uuid"
            Component={MembershipCreateUpdate}
          />
          <Route exact path="/">
            {isAuthenticated ? <Redirect to="/providers" /> : <Landing />}
          </Route>
          <Route exact path="/login">
            {isAuthenticated ? <Redirect to="/providers" /> : <Login />}
          </Route>
          <Route exact path="/register">
            {isAuthenticated ? <Redirect to="/providers" /> : <Signup />}
          </Route>
          <Route exact path="/account-confirmation">
            {isAuthenticated ? <Redirect to="/providers" /> : <AccountConfirmation />}
          </Route>
          <Route exact path="/reset-password">
            {isAuthenticated ? <Redirect to="/providers" /> : <ResetPassword />}
          </Route>
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
