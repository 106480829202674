import React, { useState } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Icon } from 'tabler-react';
import { Formik } from 'formik';
import authActions from '../../redux/reducers/auth';
import * as styles from '../../styles/not-authenticated/notauth.module.css';
import logo from '../../assets/washn logo.png';
import StandaloneFormPage from '../../helpers/StandaloneFormPage';
import getParams from '../../helpers/getParams';

function ResetPassword({ location: { search, state } }) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const query = getParams(search);
  const history = useHistory();
  document.title = 'Washn Admin - Reset Password';

  if (!state?.email && !query?.email[0]) return <Redirect to="login" />;

  return (
    <StandaloneFormPage image={logo}>
      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            code: query?.code ? query?.code[0] : '',
            password: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.code) {
              errors.code = 'Required';
            } else if (values.code.length < 6) {
              errors.code = 'Invalid code';
            }
            if (values.password.length < 8) {
              errors.password = 'Password must be at least 8 characters long';
            }
            return errors;
          }}
          onSubmit={(values) => {
            if (query.code && query.email) {
              return dispatch(
                authActions.resetPassword(
                  { email: query?.email[0], code: values.code, password: values.password },
                  () => history.push('/login'),
                ),
              );
            }
            return dispatch(
              authActions.resetPassword(
                { email: state.email, code: values.code, password: values.password },
                () => history.push('/login'),
              ),
            );
          }}
        >
          {({ values, errors, handleChange, handleSubmit }) => (
            <Form.Group label="Reset Password" className={styles.inputsContainer}>
              {!query?.code && (
                <Form.Input
                  placeholder="Code"
                  type="text"
                  value={values.code}
                  tick={!errors.code && values.code}
                  onChange={handleChange}
                  name="code"
                  error={errors.code ? errors.code : null}
                />
              )}
              <Form.InputGroup className={styles.passwordContainer}>
                <Form.Input
                  className="mt-3"
                  placeholder="New Password"
                  tick={!errors.password && values.password}
                  value={values.password}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  error={errors.password ? errors.password : null}
                />
                <Form.InputGroupAppend>
                  <button
                    onClick={() => setShowPassword(!showPassword)}
                    type="button"
                    className={styles.passwordInput}
                  >
                    <Icon name={showPassword ? 'eye-off' : 'eye'} />
                  </button>
                </Form.InputGroupAppend>
              </Form.InputGroup>
              <Button
                className="mt-3"
                block
                RootComponent="button"
                color="primary"
                onClick={handleSubmit}
                type="submit"
                disabled={!!errors.code}
              >
                Submit
              </Button>
              <Button
                block
                RootComponent="button"
                outline
                color="primary"
                onClick={() => {
                  window.location.href = '/';
                }}
                type="button"
              >
                Go Back
              </Button>
            </Form.Group>
          )}
        </Formik>
      </div>
    </StandaloneFormPage>
  );
}

export default withRouter(ResetPassword);
