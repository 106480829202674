/* eslint-disable  react/self-closing-comp, react/no-unknown-property, prettier/prettier */
import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from 'tabler-react';
import * as styles from '../../styles/not-authenticated/notauth.module.css';
// import logo from '../../assets/washn logo.png';

function Landing() {
  const history = useHistory();
  document.title = 'Washn Admin - Landing';
  return (
    <div className={styles.main}>
      <div className={styles.landingContainer}>
        <h1>Welcome to Washn</h1>
        <div className={styles.btnContainer}>
          <span>New provider? Join us!</span>
          <Button block size="lg" color="primary" outline onClick={() => history.push('/register')}>
            Sign Up
          </Button>
          <span style={{ marginTop: '20px' }}>Already a provider? Log in</span>
          <Button block size="lg" color="secondary" outline onClick={() => history.push('/login')}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Landing);
