import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  userProviders: [],
  selectedProvider: {},
  relevantOrders: [],
  workers: [],
};

const { Types, Creators } = createActions({
  getUserProviders: null,
  getSelectedProviderData: ['id'],
  getOrders: ['id'],
  checkinOrder: ['id'],
  refundOrder: ['id'],
  setUserProviders: ['userProviders'],
  setSelectedProvider: ['selectedProvider'],
  setRelevantOrders: ['relevantOrders'],
  addRelevantOrder: ['relevantOrder'],
  setWorkers: ['workers'],
  cleanState: null,
  setDashboard: ['payload'],
  orderCheckIn: ['selectedOrder', 'status'],
  dashboardSubStart: null,
  dashboardSubEnd: null,
});

const setUserProviders = (state, { userProviders }) => ({
  ...state,
  userProviders,
});
const setSelectedProvider = (state, { selectedProvider }) => ({
  ...state,
  selectedProvider,
});
const setRelevantOrders = (state, { relevantOrders }) => ({
  ...state,
  relevantOrders,
});
const addRelevantOrder = (state, { relevantOrder }) => ({
  ...state,
  relevantOrders: [...state.relevantOrders, relevantOrder],
});
const setWorkers = (state, { workers }) => ({
  ...state,
  workers,
});
const setDashboard = (_state, { payload }) => ({
  ...payload,
});

const cleanState = (state) => ({
  ...state,
  selectedProvider: [],
  relevantOrders: [],
  workers: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER_PROVIDERS]: setUserProviders,
  [Types.SET_SELECTED_PROVIDER]: setSelectedProvider,
  [Types.SET_RELEVANT_ORDERS]: setRelevantOrders,
  [Types.ADD_RELEVANT_ORDER]: addRelevantOrder,
  [Types.SET_WORKERS]: setWorkers,
  [Types.CLEAN_STATE]: cleanState,
  [Types.SET_DASHBOARD]: setDashboard,
});

export const DashboardTypes = Types;
export default Creators;
