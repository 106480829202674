import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { withRouter, Link } from 'react-router-dom';
import { Icon, Form, Button } from 'tabler-react';
import { Formik } from 'formik';
import * as styles from '../../styles/not-authenticated/notauth.module.css';
import logo from '../../assets/washn logo.png';
import getParams from '../../helpers/getParams';
import alertActions from '../../redux/reducers/alert';

function AccountConfirmation({ location: { search } }) {
  document.title = 'Washn Admin - Account Confirmation';
  const query = getParams(search);
  const [displayAlert, setDisplayAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    if (query?.code && query?.email) {
      try {
        await Auth.confirmSignUp(query?.email[0], query?.code[0]);
        setDisplayAlert(true);
      } catch (err) {
        dispatch(
          alertActions.displayAlert({
            message: err.message,
            type: 'error',
          }),
        );
      }
    }
  }, [search]);

  return (
    <div className={styles.main}>
      <div className={styles.landingContainer}>
        <img src={logo} alt="Washn Logo" />
        <h1>Welcome to Washn</h1>
        <div className={styles.btnContainer}>
          {displayAlert ? (
            <>
              <Icon prefix="fe" name="check-circle" className={styles.verificationIcon} />
              <span>
                Your account has been confirmed, you may now <Link to="/login">log in</Link>
              </span>
            </>
          ) : (
            <Formik
              initialValues={{
                code: '',
                email: '',
              }}
              validate={(values) => {
                const errors = {};
                if (!values.code) {
                  errors.code = 'Required';
                } else if (values.code.length < 6) {
                  errors.code = 'Invalid code';
                }
                if (values.email.length < 1) {
                  errors.email = 'Required';
                }
                return errors;
              }}
              onSubmit={async (values) => {
                try {
                  await Auth.confirmSignUp(values.email, values.code);
                  setDisplayAlert(true);
                } catch (err) {
                  dispatch(
                    alertActions.displayAlert({
                      message: err.message,
                      type: 'error',
                    }),
                  );
                }
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Form.Group label="Confirm your account" className={styles.inputsContainer}>
                  <Form.Input
                    placeholder="Code"
                    type="text"
                    value={values.code}
                    tick={!errors.code && values.code}
                    onChange={handleChange}
                    name="code"
                    error={errors.code ? errors.code : null}
                  />
                  <Form.InputGroup>
                    <Form.Input
                      className="mt-3"
                      placeholder="Email"
                      tick={!errors.email && values.email}
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                      name="email"
                      error={errors.email ? errors.email : null}
                    />
                  </Form.InputGroup>
                  <Button
                    className="mt-3"
                    block
                    RootComponent="button"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={!!errors.code}
                  >
                    Submit
                  </Button>
                  <Button
                    block
                    RootComponent="button"
                    outline
                    color="primary"
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    type="button"
                  >
                    Go Back
                  </Button>
                </Form.Group>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccountConfirmation);
