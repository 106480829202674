/* eslint-disable camelcase, no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form, Icon } from 'tabler-react';
// import DateTimePicker from 'react-datetime-picker';
import {
  formatDateAndTime,
  TextInput,
  IconButton,
  FormControl,
  Textarea,
  Button,
  Text,
  ButtonGroup,
} from '@contentful/f36-components';
import { TagsIcon } from '@contentful/f36-icons';
import { Formik } from 'formik';
import moment from 'moment';
import 'react-widgets/styles.css';
import DatePicker from 'react-widgets/DatePicker';
import Gallery from 'react-photo-gallery';
import SelectedImage from '../components/SelectedImage';
// import photos from '../../../../assets/photos';

import SiteWrapper from '../../../../wrappers/SiteWrapper';
import providersActions from '../../../../redux/reducers/providers';
import '../../../../styles/authenticated/providers-calendar.css';

function ServicesCreateUpdate({
  location: {
    state: { create, provider },
  },
  match: { params },
}) {
  const [toEdit, setToEdit] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectable] = useState(1);
  const [selectedImage, setSelectedImage] = useState(-1);
  const images = useSelector((state) => state.providers.userImages);
  const [imagesList, setImages] = useState(images);

  const [selectedDay, setSelectedDay] = useState(new Date());

  // console.log('PREVIEW', params.uuid);

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        selectable={selectable}
        selectAll={selectedImage === index && photo.selected}
        key={key}
        margin={25}
        right
        index={index}
        photo={photo}
        left={left}
        top={top}
        onChangeImage={handleImageChange}
      />
    ),
    [imagesList],
  );

  // const handleImageChange = (event) => {
  //   setImage(event.currentTarget.files[0]);
  //   setImagePreview(URL.createObjectURL(event.currentTarget.files[0]));
  // };

  const handleImageChange = (photo) => {
    const tempImages = images.map((i, index) => {
      if (i.id === photo.id) {
        setImage(photo.src);
        setSelectedImage(index);
        i.selected = !i.selected;
        return i;
      }
      i.selected = false;
      return i;
    });

    setImages(tempImages);
  };

  if (!create && !params.uuid) return null;

  useEffect(() => {
    if (provider.name && !create) {
      setToEdit(provider.services.find((service) => service.id === params.uuid));
    }
  }, [provider]);

  useEffect(() => {
    dispatch(providersActions.getImages());
  }, [images]);

  if (!create && !toEdit) return null;

  if (!create && toEdit.image && !imagePreview) {
    try {
      const { uri, key } = JSON.parse(toEdit.image);
      if (uri) setImagePreview(uri);
      else if (key) {
        Storage.get(key, { level: 'public' }).then((data) => setImagePreview(data));
      }
    } catch (err) {
      console.log(err);
    }
  }

  // console.log(provider);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            initialValues={{
              name: toEdit?.name || '',
              price: toEdit?.price || '',
              // created_at: new Date(),
              deal_price: toEdit?.deal_price || null,
              deal_expiry_start: toEdit?.deal_expiry_start
                ? new Date(toEdit?.deal_expiry_start)
                : null,
              deal_expiry: toEdit?.deal_expiry ? new Date(toEdit?.deal_expiry) : null,
              description: toEdit?.description || '',
            }}
            validate={(values) => {
              const errors = {};
              if (values.name.length === 0) errors.name = 'Required';
              if (values.price.length === 0) errors.price = 'Required';
              if (values.deal_price && !values.deal_expiry) {
                errors.deal_expiry = 'If you set a deal price please enter a start/end date.';
              }
              if (moment(values.deal_expiry_start).isAfter(moment(values.deal_expiry))) {
                errors.deal_expiry = 'The end date must be after the start date.';
              }
              return errors;
            }}
            validateOnChange
            onSubmit={(values) => {
              if (create) {
                return dispatch(
                  providersActions.insertService(
                    {
                      ...values,
                      image,
                      provider_id: provider.id,
                    },
                    history.push(`/providers/profile/${provider.id}`),
                  ),
                );
              }
              return dispatch(
                providersActions.updateServiceByPk(
                  {
                    ...values,
                    image,
                    id: toEdit.id,
                    provider_id: provider.id,
                    oldImageKey: toEdit.image,
                  },
                  () => history.push(`/providers/profile/${provider.id}`),
                ),
              );
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setValues }) => (
              <Form className="card" onSubmit={handleSubmit}>
                <Card.Header>
                  <Card.Title>
                    {create
                      ? `Create Service for provider location "${provider.name}"`
                      : `Editing service "${toEdit.name}"`}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Service Name</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          type="text"
                          placeholder="Service Name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          error={!!errors.name}
                          tick={!errors.name && values.name.length > 0}
                          maxLength={15}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Description</FormControl.Label>
                        <Textarea
                          resize="both"
                          autoComplete="off"
                          type="text"
                          placeholder="Description"
                          name="description"
                          onChange={handleChange}
                          value={values.description}
                          error={!!errors.description}
                          tick={!errors.description && values.description.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Price</FormControl.Label>
                        <TextInput.Group>
                          <Form.InputGroupPrepend>
                            <Button variant="secondary">$</Button>
                          </Form.InputGroupPrepend>
                          <TextInput
                            type="number"
                            placeholder="Price"
                            name="price"
                            onChange={handleChange}
                            value={values.price}
                            error={!!errors.price}
                            tick={!errors.price && values.price.length > 0}
                            pattern="^\d+(\.\d{2})?$"
                          />
                        </TextInput.Group>
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Deal Price</FormControl.Label>
                        <TextInput.Group>
                          <Form.InputGroupPrepend>
                            <Button variant="secondary">$</Button>
                          </Form.InputGroupPrepend>
                          <TextInput
                            autoComplete="off"
                            type="number"
                            placeholder="Deal Price"
                            name="deal_price"
                            onChange={handleChange}
                            value={values.deal_price}
                            tick={!!values.deal_price}
                          />
                        </TextInput.Group>
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <Form.Group>
                        <Form.Label>Deal Start Date</Form.Label>
                        <DatePicker
                          defaultValue={new Date()}
                          includeTime
                          disabled={!values.deal_price}
                          onChange={(date) => {
                            setValues({
                              ...values,
                              deal_expiry_start: date,
                            });
                          }}
                          value={values.deal_expiry_start}
                        />
                        {errors.deal_expiry && <Form.Label> {errors.deal_expiry}</Form.Label>}
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <Form.Group>
                        <Form.Label>Deal End Date</Form.Label>
                        <DatePicker
                          defaultValue={new Date()}
                          disabled={!values.deal_expiry_start}
                          includeTime
                          onChange={(date) => {
                            setValues({
                              ...values,
                              deal_expiry: date,
                            });
                          }}
                          value={values.deal_expiry}
                        />
                        {errors.deal_expiry && <Form.Label> {errors.deal_expiry}</Form.Label>}
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12" style={{ maxWidth: '50%' }}>
                      {imagePreview && (
                        <>
                          <Form.Group>
                            <Form.Label>
                              This image is assigned to the {values.name} service{' '}
                            </Form.Label>
                            <Card style={{ maxWidth: '50%', padding: 20 }}>
                              <img
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  padding: 20,
                                }}
                                src={imagePreview}
                                alt="Uploaded"
                              />
                            </Card>
                          </Form.Group>
                        </>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={12} md={12} auto className="col-12 mb-3">
                      {/* ///// Select Multiple Images /////
                      <Button
                        type="button"
                        outline
                        color="primary"
                        className="mb-3"
                        onClick={() => toggleSelectAll()}
                      >
                        select all
                      </Button> */}
                      <Gallery photos={images} renderImage={imageRenderer} />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    {/* <Grid.Col sm={6} md={6} auto className="col-12 mb-3">
                      <Form.Group label="Upload Image">
                        <Form.FileInput
                          name="image"
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </Form.Group>
                    </Grid.Col> */}
                  </Grid.Row>
                  <Grid.Row>
                    {/*  <Grid.Col md={6} auto className="col-12">
                      {imagePreview && (
                        <>
                          <Card style={{ maxWidth: '50%' }} title="Uploaded Image">
                            <img
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                              }}
                              src={imagePreview}
                              alt="Uploaded"
                            />
                          </Card>
                        </>
                      )}
                    </Grid.Col> */}
                  </Grid.Row>
                </Card.Body>
                <Card.Footer className="text-right">
                  <ButtonGroup variant="spaced" spacing="spacingM">
                    <Button
                      variant="secondary"
                      // type="button"
                      // outline
                      // color="primary"
                      className="mr-2"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="positive"
                      isDisabled={Object.keys(errors).length > 0}
                    >
                      {create ? 'Create Service' : 'Update Service'}
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ServicesCreateUpdate);
