/* eslint-disable arrow-parens */
/* eslint-disable arrow-parens, no-unused-vars, prettier/prettier, camelcase, no-undef, no-return-assign, react/no-array-index-key, max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Page, Nav, NavItem, NavLink } from 'tabler-react';
import { Row } from 'antd';
import { Card, Metric, Text, Flex, Icon } from '@tremor/react';
import { BanknotesIcon } from '@heroicons/react/24/solid';
// import { CashIcon } from '@heroicons/react/outline';
import providersActions from '../../../redux/reducers/providers';
import SiteWrapper from '../../../wrappers/SiteWrapper';

export default function Stat() {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.providers.userProviders);
  // console.log('HERE', providers[0].orders_aggregate.aggregate.count);
  // console.log('SUM', providers[0].orders_aggregate.aggregate.sum);
  // {item.orders_aggregate.aggregate.count}
  // console.log('SUM', providers);

  useEffect(() => {
    dispatch(providersActions.getProviders());
  }, [providers]);

  return (
    <div>
        {providers.map((item, index) => (
          <Grid.Row md={4} key={item.id}>
            {index % 3 === 0 && (
              <>
              <Card maxWidth="max-w-xs" decoration="top" decorationColor="indigo" key={item.id}>
              <Flex alignItems="align-top" spaceX="space-x-6">
                <Icon
                  icon={BanknotesIcon}
                  color="green"
                  variant="solid"
                  tooltip="Sum of Sales"
                  size="sm"
                />
                <Metric color="green">
                  {item.name}
                  {' '}
                  ${item.orders_aggregate.aggregate.sum.total}
                </Metric>
                </Flex>
              </Card>
              </>
            )}
          </Grid.Row>
        ))}
    </div>
  );
}
