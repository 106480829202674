import Amplify from 'aws-amplify';

const amplifyInit = () => {
  Amplify.configure({
    Auth: {
      userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AUTH_USER_WEB_CLIENT_ID,
      region: process.env.REACT_APP_AUTH_REGION,
      authenticationFlowType: process.env.REACT_APP_AUTH_AUTHENTICATION_FLOW_TYPE,
      identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AWSS3_BUCKET,
        region: process.env.REACT_APP_AWSS3_REGION,
        level: process.env.REACT_APP_AWSS3_LEVEL,
      },
    },
  });
};

export default amplifyInit;
