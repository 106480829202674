import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  show: false,
  message: '',
  type: '', // success, info, warning, error
};

const { Types, Creators } = createActions({
  displayAlert: ['payload'], // type, message
  hideAlert: null,
});

const setAlert = (state, { payload }) => ({
  ...state,
  show: true,
  message: payload.message,
  type: payload.type,
});
const hideAlert = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DISPLAY_ALERT]: setAlert,
  [Types.HIDE_ALERT]: hideAlert,
});

export const AlertTypes = Types;
export default Creators;
