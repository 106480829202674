import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { Auth } from 'aws-amplify';

const getWsClient = new SubscriptionClient(
  process.env.REACT_APP_APOLLO_URI_SUB,
  {
    reconnect: true,
    lazy: true,
    connectionParams: async () => {
      try {
        const CognitoUserSession = await Auth.currentSession().catch(() => null);
        const token = CognitoUserSession.idToken.jwtToken;
        const authorizationHeader = {};
        if (token) {
          authorizationHeader.authorization = `Bearer ${token}`;
        }
        return {
          headers: {
            ...authorizationHeader,
          },
        };
      } catch (error) {
        console.log('connectionParams error: ', error);
        return {};
      }
    },
  },
  WebSocket,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
    getWsClient.close();
    getWsClient.connect();
    Object.keys(getWsClient.operations).forEach((id) => {
      getWsClient.sendMessage(id, 'GQL_START', getWsClient.operations[id].options);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const wsLink = new WebSocketLink(getWsClient);

const subscriptionMiddleware = {
  async applyMiddleware(options, next) {
    try {
      const CognitoUserSession = await Auth.currentSession().catch(() => null);
      const token = CognitoUserSession.idToken.jwtToken;
      const authorizationHeader = {};
      if (token) {
        authorizationHeader.authorization = `Bearer ${token}`;
      }
      options.auth = {
        ...authorizationHeader,
      };
      next();
    } catch (error) {
      console.log('connectionParams error: ', error);
    }
  },
};

wsLink.subscriptionClient.use([subscriptionMiddleware]);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_APOLLO_URI,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const session = await Auth.currentSession();
    return {
      headers: {
        ...headers,
        authorization: session ? `Bearer ${session.idToken.jwtToken}` : undefined,
      },
    };
  } catch (err) {
    console.log(err);
    return {};
  }
});

const CombinedLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

const link = ApolloLink.from([errorLink, CombinedLink]);

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export default apolloClient;
