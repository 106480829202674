/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Text, Button } from 'tabler-react';
import { Card, Subheading } from '@contentful/f36-components';
import providersActions from '../../../../redux/reducers/providers';
import formActions from '../../../../redux/reducers/formDialog';
import VehicleEditor from './VehicleEditor';

export default function MembershipsTable() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.providers.customerOrders);
  const [membershipOrders, setMembershipOrders] = useState();

  const unsubscribeCustomer = (orderId, customerUuid) =>
    dispatch(providersActions.cancelMembership(orderId, customerUuid));

  useEffect(() => {
    let data = orders.filter((item) => item.status === 'ACTIVE_MEMBERSHIP');

    data = data.map((order) => {
      const details = order.details[0];
      return {
        id: order.id,
        name: details.membership.name,
        vehicleId: details.vehicle.id,
        price: order.total,
        customerUuid: order.user.id,
      };
    });

    setMembershipOrders(data);
  }, [orders]);

  if (!membershipOrders) return null;

  // console.log('MEM', orders);

  return (
    <Card
      className="pt-3"
      title={
        <>
          <Subheading marginTop="spacingS" marginBottom="spacingM" marginLeft="spacingS">
            Active Memberships
          </Subheading>
        </>
      }
    >
      {membershipOrders.length === 0 ? (
        <Text center muted>
          No active memberships
        </Text>
      ) : (
        <>
          <Table
            style={{ fontSize: 12 }}
            highlightRowOnHover
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: 'Name' },
              { content: 'Vehicle' },
              { content: 'Price' },
              { content: '' },
            ]}
            bodyItems={membershipOrders.map((membershipOrder) => ({
              key: membershipOrder.id,
              item: [
                { content: membershipOrder.name },
                {
                  content: <VehicleEditor membershipOrder={membershipOrder} />,
                },
                { content: `$${membershipOrder.price.toFixed(2)}` },
                {
                  content: (
                    <Button
                      size="sm"
                      color="danger"
                      className="mr-2"
                      onClick={() =>
                        unsubscribeCustomer(membershipOrder.id, membershipOrder.customerUuid)
                      }
                    >
                      Cancel
                    </Button>
                  ),
                },
                {
                  content: (
                    <Button
                      size="sm"
                      color="danger"
                      className="mr-2"
                      onClick={() =>
                        dispatch(
                          formActions.displayForm({
                            title: 'Confirm Cancel',
                            description: "Are you sure you want to cancel the user's subscription?",
                            handleSubmit: () =>
                              unsubscribeCustomer(membershipOrder.id, membershipOrder.customerUuid),
                            closeButtonText: 'Cancel',
                            submitButtonText: 'Confirm',
                          }),
                        )
                      }
                      // onClick={() =>
                      //   unsubscribeCustomer(membershipOrder.id, membershipOrder.customerUuid)
                      // }
                    >
                      Cancel Membership
                    </Button>
                  ),
                },
              ],
            }))}
          />
        </>
      )}
    </Card>
  );
}
