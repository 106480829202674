import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import formDialogActions from '../redux/reducers/formDialog';

export default function AlertDialog() {
  const { title, description, show, submitButtonText, handleSubmit, closeButtonText } = useSelector(
    (state) => state.formDialog,
  );

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(formDialogActions.hideForm());
  };

  return (
    <div>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {closeButtonText}
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
