import { all, fork } from 'redux-saga/effects';
import auth from './auth';
import profile from './profile';
import dashboard from './dashboard';
import providers from './providers';

const forkList = (sagasList) => sagasList.map((saga) => fork(saga));

export default function* rootSaga() {
  yield all([...forkList(auth)]);
  yield all([...forkList(profile)]);
  yield all([...forkList(dashboard)]);
  yield all([...forkList(providers)]);
}
