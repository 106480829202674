/* eslint-disable max-len */
import * as React from 'react';
import { Card, Stack } from '@contentful/f36-components';

function PricingPage() {
  return (
    <>
      <Stack flexDirection="row" spacing="spacingS">
        {/**
        <Card>
          <stripe-pricing-table
            pricing-table-id="prctbl_1LU9EpDZI2FDegHvzxkqwR9e"
            publishable-key="pk_test_YGCcFFL8A8hxzDf7mpNykDdS"
          />
        </Card>
        */}
        <Card>
          <stripe-pricing-table
            pricing-table-id="prctbl_1MyvfsApdrqIxvCfp5w3wFB2" // Staging
            // pricing-table-id="prctbl_1Mywp0ApdrqIxvCfrQL6ZMaG" // Prod
            // pricing-table-id="prctbl_1M4qUqApdrqIxvCfnzkc5q2g"
            // "prctbl_1MOnrSApdrqIxvCfLquWIWCP"
            publishable-key="pk_test_51I3S1gApdrqIxvCfFkxel5MKorQWgK9l7msrAFpQyH40L7pTdLCWujNvNWGPQoySy7ZzLokR7917b6oe5xTt6gcZ00Hh03B5ho" // Staging
            // publishable-key="pk_live_51I3S1gApdrqIxvCfjTlCNUlII04rXePPc5ajG2B7oRlOeM1CZT3EQvkql7McHJk61RCpwstcT4ukfKDtVBgdKpHD00jqeRWACr" // Prod
          />
        </Card>
      </Stack>
    </>
  );
}

export default PricingPage;
