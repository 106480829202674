/* eslint-disable func-names */
/* eslint-disable arrow-body-style */
import * as Sentry from '@sentry/react';

const safe = (sagaFuncion) => {
  return function* (action) {
    try {
      yield* sagaFuncion(action);
    } catch (err) {
      Sentry.captureException(err);
    }
  };
};

export default safe;
