import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  authenticated: null,
};

const { Types, Creators } = createActions({
  setUser: ['payload'],
  createProfile: ['payload'],
  updateProfile: ['payload'],
  signUp: ['username', 'password', 'callback'],
  signIn: ['username', 'password'],
  forgotPassword: ['username', 'callback', 'next'],
  confirmForgotPassword: ['formData', 'callback'],
  logout: null,
  verifyAccount: ['email', 'code', 'callback'],
  resetPassword: ['payload', 'callback'],
  workerSignup: ['name', 'email', 'providerId', 'callback'],
});

const setUser = (state, { payload }) => ({
  ...state,
  user: payload.user,
  authenticated: payload.authenticated,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: setUser,
});

export const AuthTypes = Types;
export default Creators;
