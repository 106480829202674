import { gql } from '@apollo/client';
import apolloClient from '../helpers/ApolloClient';

class Providers {
  static getUserProviders() {
    return apolloClient.query({
      query: gql`
        query GetUserProviders {
          provider {
            id
            name
            address
            phone
            stripe_account
            stripe_verified
            zip
            email
            verified
            sitenum
            location_id
            mobile_store
            orders_aggregate {
              aggregate {
                count
                sum {
                  total
                }
              }
            }
          }
        }
      `,
    });
  }

  static getSumReports() {
    return apolloClient.query({
      query: gql`
        query getSumReports {
          order_aggregate {
            aggregate {
              count
              sum {
                total
              }
            }
          }
        }
      `,
    });
  }

  static getReports() {
    return apolloClient.query({
      query: gql`
        query GetReports {
          order {
            id
            number
            provider_id
            washn_code
            status
            user_id
            user {
              profile {
                name
                lastname
              }
              email
              id
            }
            created_at
            charge_status
            checkin_at
            details {
              service {
                name
              }
              addon {
                name
              }
              membership {
                name
              }
            }
            total
          }
        }
      `,
    });
  }

  static getImages() {
    return apolloClient.query({
      query: gql`
        query GetImages {
          service_image_link {
            id
            title
            src
          }
        }
      `,
    });
  }

  static getProviderByPk({ uuid }) {
    return apolloClient.query({
      query: gql`
        query GetProviderByPk($uuid: uuid!) {
          provider_by_pk(id: $uuid) {
            id
            name
            address
            location
            business_type
            city
            state
            mobile_store
            decision_maker_name
            decision_maker_lastname
            email
            image
            phone
            point_of_contact_name
            point_of_contact_lastname
            video
            website
            zip
            sitenum
            location_id
            deleted_at
            verified
            admin_approved
            stripe_account
            stripe_verified
            location
            stripe_cust_id
            stripe_cust_status
            washn_fee_percentage
            workers {
              user {
                role
                email
                id
                profile {
                  name
                  picture
                }
              }
            }
            memberships {
              name
              description
              price
              visible
              creation_date
              stripe_product_id
              washn_fee_percentage
              id
              order_details(where: { order: { status: { _eq: "ACTIVE_MEMBERSHIP" } } }) {
                id
              }
            }
            services {
              id
              image
              name
              price
              description
              deal_price
              deal_expiry
              deal_expiry_start
              created_at
              modified_at
              deleted_at
            }
            addons {
              id
              image
              name
              price
              created_at
              modified_at
              deleted_at
            }
            addons_aggregate {
              aggregate {
                count
              }
            }
            services_aggregate {
              aggregate {
                count
              }
            }
            orders_aggregate {
              aggregate {
                count
                sum {
                  total
                }
              }
            }
          }
        }
      `,
      variables: {
        uuid,
      },
    });
  }

  static insertProvider(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertProvider(
          $email: String
          $phone: String
          $point_of_contact_name: String
          $point_of_contact_lastname: String
          $decision_maker_name: String
          $decision_maker_lastname: String
          $address: String
          $city: String
          $state: String
          $zip: String
          $website: String
          $business_type: String
          $name: String
          $userId: uuid
          $image: String
          $location: geography
        ) {
          insert_provider_one(
            object: {
              email: $email
              phone: $phone
              point_of_contact_name: $point_of_contact_name
              point_of_contact_lastname: $point_of_contact_lastname
              decision_maker_name: $decision_maker_name
              decision_maker_lastname: $decision_maker_lastname
              address: $address
              city: $city
              state: $state
              zip: $zip
              website: $website
              business_type: $business_type
              name: $name
              image: $image
              location: $location
              workers: { data: { user_id: $userId } }
            }
          ) {
            email
            phone
            point_of_contact_name
            point_of_contact_lastname
            decision_maker_name
            decision_maker_lastname
            address
            city
            state
            zip
            website
            business_type
            name
          }
        }
      `,
    });
  }

  static updateProviderByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation UpdateProvider(
          $id: uuid!
          $email: String
          $phone: String
          $point_of_contact_name: String
          $point_of_contact_lastname: String
          $decision_maker_name: String
          $decision_maker_lastname: String
          $address: String
          $city: String
          $state: String
          $zip: String
          $website: String
          $business_type: String
          $name: String
          $deleted_at: timestamptz
          $image: String
          $location: geography
        ) {
          update_provider_by_pk(
            _set: {
              email: $email
              phone: $phone
              point_of_contact_name: $point_of_contact_name
              point_of_contact_lastname: $point_of_contact_lastname
              decision_maker_name: $decision_maker_name
              decision_maker_lastname: $decision_maker_lastname
              address: $address
              city: $city
              state: $state
              zip: $zip
              website: $website
              business_type: $business_type
              name: $name
              image: $image
              location: $location
            }
            pk_columns: { id: $id }
          ) {
            email
            phone
            point_of_contact_name
            point_of_contact_lastname
            decision_maker_name
            decision_maker_lastname
            address
            city
            state
            zip
            website
            business_type
            name
          }
        }
      `,
    });
  }

  static updateProviderMobileStoreAvailabilityByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation UpdateProvider($id: uuid!, $mobileStore: Boolean) {
          update_provider_by_pk(_set: { mobile_store: $mobileStore }, pk_columns: { id: $id }) {
            name
          }
        }
      `,
    });
  }

  static deleteProviderByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation DeleteProvider($deleted_at: timestamptz, $id: uuid!) {
          update_provider_by_pk(_set: { deleted_at: $deleted_at }, pk_columns: { id: $id }) {
            email
            phone
            point_of_contact_name
            decision_maker_name
            address
            city
            state
            zip
            website
            business_type
            name
          }
        }
      `,
    });
  }

  static insertService(data) {
    // console.log({ ...data });
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertService(
          $name: String
          $price: numeric
          $deal_price: numeric
          $created_at: timestamptz
          $deal_expiry_start: timestamptz
          $deal_expiry: timestamptz
          $description: String
          $image: String
          $provider_id: uuid!
        ) {
          insert_service_one(
            object: {
              name: $name
              price: $price
              deal_expiry_start: $deal_expiry_start
              deal_expiry: $deal_expiry
              deal_price: $deal_price
              created_at: $created_at
              description: $description
              image: $image
              provider_id: $provider_id
            }
          ) {
            name
          }
        }
      `,
    });
  }

  static updateServiceByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation UpdateService(
          $id: uuid!
          $name: String
          $price: numeric
          $deal_price: numeric
          $deal_expiry_start: timestamptz
          $deal_expiry: timestamptz
          $description: String
          $modified_at: timestamptz
          $image: String
        ) {
          update_service_by_pk(
            _set: {
              name: $name
              price: $price
              deal_expiry: $deal_expiry
              deal_expiry_start: $deal_expiry_start
              deal_price: $deal_price
              modified_at: $modified_at
              description: $description
              image: $image
            }
            pk_columns: { id: $id }
          ) {
            name
          }
        }
      `,
    });
  }

  static insertAddon(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertAddon(
          $name: String
          $price: numeric
          $created_at: timestamptz
          $image: String
          $provider_id: uuid!
        ) {
          insert_service_addon_one(
            object: {
              name: $name
              price: $price
              created_at: $created_at
              image: $image
              provider_id: $provider_id
            }
          ) {
            name
          }
        }
      `,
    });
  }

  static updateAddonByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation UpdateAddon(
          $id: uuid!
          $name: String
          $price: numeric
          $modified_at: timestamptz
          $image: String
        ) {
          update_service_addon_by_pk(
            _set: { name: $name, price: $price, modified_at: $modified_at, image: $image }
            pk_columns: { id: $id }
          ) {
            name
          }
        }
      `,
    });
  }

  static deleteServiceByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation DeleteService($deleted_at: timestamptz, $id: uuid!) {
          update_service_by_pk(_set: { deleted_at: $deleted_at }, pk_columns: { id: $id }) {
            name
          }
        }
      `,
    });
  }

  static deleteAddonByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation DeleteAddon($deleted_at: timestamptz, $id: uuid!) {
          update_service_addon_by_pk(_set: { deleted_at: $deleted_at }, pk_columns: { id: $id }) {
            name
          }
        }
      `,
    });
  }

  static insertServiceImage(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertServiceImage($name: String, $src: String) {
          insert_service_image_link_one(object: { title: $name, src: $src }) {
            title
          }
        }
      `,
    });
  }

  // static deleteServiceImageByPk(data) {
  //   return apolloClient.mutate({
  //     variables: { ...data },
  //     mutation: gql`
  //       mutation DeleteServiceImage($deleted_at: timestamptz, $id: uuid!) {
  //         update_service_image_link_by_pk(
  //           _set: { deleted_at: $deleted_at }
  //           pk_columns: { id: $id }
  //         ) {
  //           title
  //         }
  //       }
  //     `,
  //   });
  // }

  static openStripeLink(providerId) {
    return apolloClient.mutate({
      variables: { id: providerId },
      mutation: gql`
        mutation ConnectProviderStripe($id: String!) {
          connectProviderStripe(provider_id: $id) {
            onboarding_url
          }
        }
      `,
    });
  }

  static replaceMembershipVehicle(orderId, newVehicleId) {
    return apolloClient.mutate({
      variables: { orderId, newVehicleId },
      mutation: gql`
        mutation ReplaceMembershipVehicle($orderId: String!, $newVehicleId: String!) {
          replaceMembershipVehicle(orderId: $orderId, newVehicleId: $newVehicleId)
        }
      `,
    });
  }

  static getStripeLink(stripeAccount) {
    return apolloClient.query({
      variables: { stripe_account: stripeAccount },
      query: gql`
        query GetStripeLink($stripe_account: String!) {
          getStripeLink(stripe_account: $stripe_account) {
            onboarding_url
          }
        }
      `,
    });
  }

  static disableWorker(email, providerId) {
    return apolloClient.mutate({
      variables: { email, providerId },
      mutation: gql`
        mutation DisableWorker($email: String!, $providerId: String!) {
          disableWorker(email: $email, providerId: $providerId)
        }
      `,
    });
  }

  static cancelMembership(orderId) {
    return apolloClient.mutate({
      variables: { orderId },
      mutation: gql`
        mutation CancelMembership($orderId: String!) {
          cancelCustomerMembership(orderId: $orderId) {
            status
          }
        }
      `,
    });
  }

  static getCustomersByPk({ uuid }) {
    return apolloClient.query({
      variables: {
        uuid,
      },
      query: gql`
        query getCustomersByPk($uuid: uuid!) {
          order(where: { provider_id: { _eq: $uuid } }, distinct_on: user_id) {
            user {
              profile {
                name
                lastname
                address
                city
                phone_number
                state
                zip
                verified_location
              }
              email
              id
              vehicles {
                nickname
                year
                color
                maker {
                  name
                }
                model {
                  name
                  category {
                    name
                  }
                }
                state
                image
                is_default
                license
              }
            }
            details {
              service {
                name
              }
              addon {
                name
              }
              membership {
                name
              }
            }
          }
        }
      `,
    });
  }

  static getCustomerOrdersByPk({ uuid }) {
    return apolloClient.query({
      variables: { uuid },
      query: gql`
        query getCustomerOrdersByPk($uuid: uuid!) {
          order(where: { user_id: { _eq: $uuid } }) {
            id
            status
            number
            provider {
              id
            }
            user {
              profile {
                name
                lastname
                address
                city
                phone_number
                state
                zip
                picture
                verified_location
              }
              email
              id
              vehicles {
                image
                id
                color
                deleted_at
                nickname
                year
                state
                maker {
                  name
                }
                model {
                  name
                  category {
                    name
                  }
                }
                license
                membership_order_detail {
                  order {
                    status
                  }
                }
              }
              vehicles_aggregate(where: { user_id: { _eq: $uuid } }) {
                aggregate {
                  count
                }
              }
            }
            created_at
            washn_code
            charge_status
            details {
              service {
                name
              }
              addon {
                name
              }
              membership {
                name
              }
              vehicle {
                id
                maker {
                  name
                }
                model {
                  name
                  category {
                    name
                  }
                }
                license
                state
                year
                color
              }
            }
            total
          }
        }
      `,
    });
  }

  static getCustomerVehiclesByPk({ uuid }) {
    return apolloClient.query({
      variables: { uuid },
      query: gql`
        query getCustomerVehiclesByPk($uuid: uuid!) {
          vehicle(where: { user_id: { _eq: $uuid } }) {
            color
            year
            nickname
            maker {
              name
            }
            model {
              name
              category {
                name
              }
            }
            state
            license
            is_default
            image
            id
          }
          vehicle_aggregate(where: { user_id: { _eq: $uuid } }) {
            aggregate {
              count
            }
          }
        }
      `,
    });
  }

  static getSubscriptions() {
    return apolloClient.query({
      query: gql`
        query getSubscriptions {
          subscriptions {
            link
            plan_name
            plan_price
          }
        }
      `,
    });
  }

  static getProduct({ productId, providerStripeAccount }) {
    return apolloClient.query({
      variables: { productId, providerStripeAccount },
      query: gql`
        query GetProduct($productId: String!, $providerStripeAccount: String!) {
          getProduct(product_id: $productId, provider_stripe_account: $providerStripeAccount) {
            id
            currency
            active
            name
            description
            statement_descriptor
            default_price
            stripe_price_id
          }
        }
      `,
    });
  }

  static insertMembership(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertMembership(
          $name: String
          $description: String
          $price: numeric
          $providerId: uuid!
          $productId: String
          $stripePriceId: String
          $washnFeePercentage: numeric
        ) {
          insert_memberships_one(
            object: {
              name: $name
              description: $description
              price: $price
              provider_id: $providerId
              stripe_product_id: $productId
              stripe_price_id: $stripePriceId
              washn_fee_percentage: $washnFeePercentage
            }
          ) {
            name
          }
        }
      `,
    });
  }

  static updateMembershipVisibility(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation updateMembershipsByPk($id: uuid!, $visible: Boolean) {
          update_memberships_by_pk(_set: { visible: $visible }, pk_columns: { id: $id }) {
            name
          }
        }
      `,
    });
  }
}

export default Providers;
