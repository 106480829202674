import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  show: false,
  title: '',
  description: '',
  submitButtonText: undefined,
  handleSubmit: undefined,
  closeButtonText: undefined,
};

const { Types, Creators } = createActions({
  displayForm: ['payload'],
  hideForm: null,
});

const setForm = (state, { payload }) => ({ ...payload, show: true });

const hideForm = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DISPLAY_FORM]: setForm,
  [Types.HIDE_FORM]: hideForm,
});

export const FormTypes = Types;
export default Creators;
