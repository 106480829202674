/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

function ProtectedRoute(props) {
  const { isAuthenticated, path, Component, ...rest } = props;

  if (isAuthenticated === null) return null;
  if (!isAuthenticated) return <Redirect to="/" />;

  return (
    <Route exact path={path}>
      <Component {...rest} />
    </Route>
  );
}

export default withRouter(ProtectedRoute);
