import { gql } from '@apollo/client';
import { Auth } from 'aws-amplify';
import apolloClient from '../helpers/ApolloClient';

export default class AuthApi {
  static signUp = async (username, password) => {
    await Auth.signUp({
      username,
      password,
      clientMetadata: {
        role: 'provider',
      },
      attributes: {
        'custom:role': 'provider',
      },
    });
  };

  static signIn = async (username, password) => {
    await Auth.signIn({ username, password });
  };

  static forgotPassword = async (username) => {
    await Auth.forgotPassword(username, { role: 'provider' });
  };

  static confirmForgotPassword = async ({ code, email, newPassword }) => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  static logOut = async () => {
    await Auth.signOut();
  };

  static showSession = async () => {
    await Auth.currentSession();
  };

  static verifyAccount = async (email, code) => {
    await Auth.confirmSignUp(email, code);
  };

  static resetPassword = async (email, code, password) => {
    await Auth.forgotPasswordSubmit(email, code, password);
  };

  static workerSignup(email, providerId) {
    return apolloClient.mutate({
      variables: { email, providerId },
      mutation: gql`
        mutation WorkerSignup($email: String!, $providerId: String!) {
          createWorker(email: $email, providerId: $providerId)
        }
      `,
    });
  }
}
