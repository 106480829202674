import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = false;

const { Types, Creators } = createActions({
  setLoading: ['payload'],
});

const setLoading = (state, { payload }) => payload;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoading,
});

export const LoadingTypes = Types;
export default Creators;
