/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import alertActions from '../redux/reducers/alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertState = () => {
  const dispatch = useDispatch();

  const { show, message, type } = useSelector((state) => state.alert);

  const onClose = () => dispatch(alertActions.hideAlert());
  // console.log('ALERTTTT', message);

  if (!show) return null;

  return (
    <Snackbar open={show} autoHideDuration={6000} onClose={onClose} style={{ zIndex: 10000 }}>
      <Alert severity={type} onClose={onClose} style={{ fontSize: '1rem' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertState;
