import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import sagas from './sagas';
import reducers from './reducers/index';
import authApi from '../libs/Auth';
import userApi from '../libs/User';
import providersApi from '../libs/Providers';
import imagesApi from '../libs/Images';
import adminApi from '../libs/Admin';

const storeInit = () => {
  // TODO: make navigationService static
  const sagaMiddleware = createSagaMiddleware({
    context: {
      authApi,
      userApi,
      providersApi,
      imagesApi,
      adminApi,
    },
    onError: (err) => Sentry.captureException(err),
  });

  const store = createStore(reducers(), applyMiddleware(...[sagaMiddleware]));

  sagaMiddleware.run(sagas);

  return store;
};

export default storeInit();
