/* eslint-disable react/jsx-props-no-spreading, no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form } from 'tabler-react';
import {
  Select,
  ButtonGroup,
  Button,
  Stack,
  FormControl,
  TextInput,
  Tooltip,
  Icon,
} from '@contentful/f36-components';
import { Formik } from 'formik';
import { FormHelperText } from '@material-ui/core';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import providersActions from '../../../redux/reducers/providers';
import debounce from '../../../helpers/debounce';

function ProvidersCreate() {
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const [input, setInput] = useState('');
  const [autocompleteData, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleImageChange = (event) => {
    setImage(event.currentTarget.files[0]);
    setImagePreview(URL.createObjectURL(event.currentTarget.files[0]));
  };
  const { hereApiKey } = useSelector((state) => state.profile);
  const getAddresses = useMemo(() => debounce((func) => func(), 400), []);
  useEffect(() => {
    setLoading(true);
    getAddresses(async () => {
      try {
        if (input.length < 1) return;
        const { data } = await axios(
          `https://autocomplete.search.hereapi.com/v1/geocode?q=${input}&apiKey=${hereApiKey}&limit=5&at=39.829978517298066,-98.5798078531771&lang=en-US&in=countryCode:USA`,
        );

        setData(data.items);
        console.log('HERE', data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    });
  }, [input, getAddresses]);
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              point_of_contact_name: '',
              point_of_contact_lastname: '',
              decision_maker_name: '',
              decision_maker_lastname: '',
              state: '',
              city: '',
              address: undefined,
              zip: '',
              website: '',
              business_type: '',
              address_object: false,
            }}
            validate={(values) => {
              const errors = {};
              if (values.name.length === 0) errors.name = 'Required';
              if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email';
              }
              if (values.phone.length === 0) errors.phone = 'Required';
              if (values.state.length === 0 || values.state === 'Please select a state') {
                errors.state = 'Required';
              }
              if (values.city.length === 0) errors.city = 'Required';

              if (values.zip.length === 0) errors.zip = 'Required';
              if (!values.address_object) errors.address_object = true;

              if (values.point_of_contact_name.length === 0) {
                errors.point_of_contact_name = 'Required';
              }
              if (values.point_of_contact_lastname.length === 0) {
                errors.point_of_contact_lastname = 'Required';
              }
              if (values.decision_maker_name.length === 0) {
                errors.decision_maker_name = 'Required';
              }
              if (values.decision_maker_lastname.length === 0) {
                errors.decision_maker_lastname = 'Required';
              }

              if (values.business_type.length === 0) {
                errors.business_type = 'Required';
              }

              return errors;
            }}
            validateOnChange
            onSubmit={(values) => {
              dispatch(providersActions.insertProvider({ ...values, image }, user.id));
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setValues }) => (
              <form className="card" onSubmit={handleSubmit} autoComplete="off">
                <Card.Body>
                  <Card.Title>Create Provider Location</Card.Title>
                  <Grid.Row>
                    <Grid.Col sm={6} md={6} className="col-12">
                      <FormControl isRequired>
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="This is the name customers will see in the Washn App."
                        >
                          <FormControl.Label>Location Name</FormControl.Label>
                        </Tooltip>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          type="text"
                          placeholder="Location Name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          error={!!errors.name}
                          tick={!errors.name && values.name.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col sm={6} md={3} auto className="col-12">
                      <FormControl isRequired>
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="An emaikl address required for each location. Visit the support center for more information."
                        >
                          <FormControl.Label>Email</FormControl.Label>
                        </Tooltip>
                        <TextInput
                          // size="small"
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          value={values.email}
                          error={!!errors.email}
                          tick={!errors.email && values.email.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col sm={6} md={3} auto className="col-12">
                      <FormControl isRequired>
                        <FormControl.Label>Location Phone #</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          type="tel"
                          placeholder="Phone"
                          name="phone"
                          onChange={handleChange}
                          value={values.phone}
                          error={!!errors.phone}
                          tick={!errors.phone && values.phone.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl isRequired>
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="The name of the on-site contact (this is usually operator, manager of supervisor)."
                        >
                          <FormControl.Label>Point of Contact</FormControl.Label>
                        </Tooltip>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          placeholder="Firstname"
                          name="point_of_contact_name"
                          onChange={handleChange}
                          value={values.point_of_contact_name}
                          error={!!errors.point_of_contact_name}
                          tick={
                            !errors.point_of_contact_name && values.point_of_contact_name.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl isRequired>
                        <FormControl.Label />
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          placeholder="Lastname"
                          name="point_of_contact_lastname"
                          onChange={handleChange}
                          value={values.point_of_contact_lastname}
                          error={!!errors.point_of_contact_lastname}
                          tick={
                            !errors.point_of_contact_lastname &&
                            values.point_of_contact_lastname.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl isRequired>
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="This is the name of the person who is authorized to make business decisions"
                        >
                          <FormControl.Label>Decision Maker</FormControl.Label>
                        </Tooltip>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          placeholder="Firstname"
                          name="decision_maker_name"
                          onChange={handleChange}
                          value={values.decision_maker_name}
                          error={!!errors.decision_maker_name}
                          tick={
                            !errors.decision_maker_name && values.decision_maker_name.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl isRequired>
                        <FormControl.Label>Decision Maker</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          placeholder="Lastname"
                          name="decision_maker_lastname"
                          onChange={handleChange}
                          value={values.decision_maker_lastname}
                          error={!!errors.decision_maker_lastname}
                          tick={
                            !errors.decision_maker_lastname &&
                            values.decision_maker_lastname.length > 0
                          }
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={6} auto className="mb-3 col-12">
                      <FormControl isRequired>
                        <Tooltip
                          placement="top"
                          id="tooltip-1"
                          content="Enter the full address and select from the list."
                        >
                          <FormControl.Label>Location Address</FormControl.Label>
                        </Tooltip>
                        <Autocomplete
                          // id="free-solo-2-demo"
                          options={autocompleteData}
                          inputValue={input}
                          loading={loading}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          getOptionSelected={(option, value) => option.title === value.title}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              const {
                                address: { stateCode, postalCode, city, houseNumber, street },
                              } = newValue;

                              if (houseNumber) {
                                setValues((form) => ({
                                  ...form,
                                  city,
                                  state: stateCode ? `${stateCode}` : city,
                                  zip: postalCode.split('-')[0],
                                  address: `${houseNumber} ${street}`,
                                  address_object: newValue,
                                }));
                              } else {
                                setValues((form) => ({
                                  ...form,
                                  city,
                                  state: stateCode ? `${stateCode}` : city,
                                  zip: postalCode.split('-')[0],
                                  address: street,
                                }));
                              }
                            }
                          }}
                          style={{ flex: 9 }}
                          onInputChange={(event, newInputValue) => {
                            setInput(newInputValue);
                          }}
                          getOptionLabel={(option) => option.title}
                          filterOptions={(x) => x}
                          renderInput={(param) => (
                            <div
                              ref={param.InputProps.ref}
                              style={{
                                // backgroundColor: '#1D4C6F',
                                borderRadius: 5,
                                width: '100%',
                                height: '38px',
                              }}
                            >
                              <input
                                type="search"
                                autoComplete="new-password"
                                placeholder="i.e: 1 Liberty St, New York, NY 10005"
                                {...param.inputProps}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  padding: '6px 12px',
                                  border: '1px solid rgba(0, 40, 100, 0.12)',
                                  borderRadius: 5,
                                }}
                              />
                              {errors.address_object && (
                                <FormHelperText error>
                                  You must enter an address above and choose one of the options.
                                </FormHelperText>
                              )}
                            </div>
                          )}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>City</FormControl.Label>
                        <TextInput
                          // size="small"
                          autoComplete="off"
                          type="text"
                          isReadOnly
                          // disabled
                          placeholder="New York"
                          name="city"
                          onChange={handleChange}
                          value={values.city}
                          error={!!errors.city}
                          tick={!errors.city && values.city.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>State</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          isReadOnly
                          // disabled
                          placeholder="NY"
                          name="state"
                          onChange={handleChange}
                          value={values.state}
                          error={!!errors.state}
                          tick={!errors.state && values.state.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={2} auto className="col-12">
                      <FormControl isRequired>
                        <FormControl.Label>Zipcode</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          isReadOnly
                          // disabled
                          placeholder="10005"
                          name="zip"
                          onChange={handleChange}
                          value={values.zip}
                          error={!!errors.zip}
                          tick={!errors.zip && values.zip.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl isRequired>
                        <FormControl.Label>Business Type</FormControl.Label>
                        <Select
                          id="optionSelect-controlled"
                          // size="small"
                          // name="optionSelect-controlled"
                          onChange={handleChange}
                          value={values.business_type}
                          name="business_type"
                        >
                          <Select.Option>---</Select.Option>
                          <Select.Option value="Individual">Individual</Select.Option>
                          <Select.Option value="Corporation">Corporation</Select.Option>
                          <Select.Option value="LLC">LLC</Select.Option>
                          <Select.Option value="Sole Proprietor">Sole Proprietor</Select.Option>
                        </Select>
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Website</FormControl.Label>
                        <TextInput
                          autoComplete="off"
                          // size="small"
                          type="text"
                          placeholder="https://www.mycarwash.com"
                          name="website"
                          onChange={handleChange}
                          value={values.website}
                          tick={values.website.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Location Logo</FormControl.Label>
                        <TextInput
                          type="file"
                          name="image"
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </FormControl>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={6} className="col-12">
                      {imagePreview && (
                        <>
                          <Card style={{ maxWidth: '100%' }}>
                            <img
                              style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                              }}
                              src={imagePreview}
                              alt="Uploaded"
                            />
                          </Card>
                        </>
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
                <Card.Footer className="text-right">
                  <ButtonGroup variant="spaced" spacing="spacingM">
                    <Button onClick={() => window.history.back()} variant="secondary">
                      Cancel
                    </Button>
                    <Button type="submit" variant="positive">
                      Create Provider Location
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersCreate);
