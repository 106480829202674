/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
// import { Storage } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form } from 'tabler-react';
import { Formik } from 'formik';
import {
  ButtonGroup,
  Button,
  FormControl,
  TextInput,
  IconButton,
} from '@contentful/f36-components';
import Gallery from 'react-photo-gallery';
import SelectedImage from './components/SelectedImage';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import providersActions from '../../../redux/reducers/providers';

function ProvidersImages() {
  // const [title, setTitle] = useState('');
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const dispatch = useDispatch();
  //   const history = useHistory();
  const [selectable] = useState(0);
  const images = useSelector((state) => state.providers.userImages);
  const user = useSelector((state) => state.auth.user);

  // console.log(user);
  const imageRenderer = useCallback(({ index, left, top, key, photo }) => (
    <SelectedImage
      selectable={selectable}
      key={key}
      margin={25}
      right
      index={index}
      photo={photo}
      left={left}
      top={top}
    />
  ));

  const handleImageChange = (event) => {
    // console.log(event.currentTarget.files[0]);
    // setTitle(event.currentTarget.files[0].name);
    setImage(event.currentTarget.files[0]);
    setImagePreview(URL.createObjectURL(event.currentTarget.files[0]));
  };

  useEffect(() => {
    dispatch(providersActions.getImages());
  }, [images]);

  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        {user.role === 'admin' && (
          <Container>
            <Formik
              initialValues={{
                name: '',
              }}
              validate={(values) => {
                const errors = {};
                if (values.name.length === 0) errors.name = 'Required';
                return errors;
              }}
              validateOnChange
              onSubmit={(values) => {
                dispatch(
                  providersActions.insertServiceImage({
                    ...values,
                    // title,
                    image,
                    user_id: user.id,
                  }),
                );
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Form className="card" onSubmit={handleSubmit}>
                  <Card.Header>
                    <Card.Title>Add Images To The Gallery For Services and Add-Ons</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col md={4} auto className="col-12">
                        <FormControl>
                          <FormControl.Label>Service Name</FormControl.Label>
                          <TextInput
                            autoComplete="off"
                            type="text"
                            placeholder="Service Name"
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                            error={!!errors.name}
                            tick={!errors.name && values.name.length > 0}
                          />
                        </FormControl>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={4} auto className="col-12">
                        <FormControl>
                          <FormControl.Label>Image</FormControl.Label>
                          <TextInput
                            type="file"
                            // name="image"
                            onChange={handleImageChange}
                            accept="image/*" // accepts only png file formats
                          />
                        </FormControl>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={4} className="col-12">
                        {imagePreview && (
                          <>
                            <Card style={{ maxWidth: '100%' }}>
                              <img
                                style={{
                                  paddingBottom: 15,
                                  paddingRight: 20,
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  marginTop: '1rem',
                                  marginLeft: '1rem',
                                }}
                                src={imagePreview}
                                alt="Uploaded"
                              />
                            </Card>
                          </>
                        )}
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col md={14} auto className="col-12">
                        <Gallery
                          photos={images}
                          renderImage={imageRenderer}
                          className="row"
                          style={{ paddingBottom: 15 }}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                  <Card.Footer className="text-right">
                    <ButtonGroup variant="spaced" spacing="spacingM">
                      <Button
                        variant="secondary"
                        type="button"
                        // outline
                        // color="primary"
                        className="mr-2"
                        onClick={() => window.history.back()}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" variant="positive">
                        Upload
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                </Form>
              )}
            </Formik>
          </Container>
        )}
      </div>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersImages);
