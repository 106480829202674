/* eslint-disable implicit-arrow-linebreak */
import { combineReducers } from 'redux';
import { reducer as auth } from './auth';
import { reducer as profile } from './profile';
import { reducer as alert } from './alert';
import { reducer as formDialog } from './formDialog';
import { reducer as dashboard } from './dashboard';
import { reducer as providers } from './providers';
import { reducer as loading } from './loading';

const configureReducers = () =>
  combineReducers({
    auth,
    profile,
    alert,
    dashboard,
    providers,
    formDialog,
    loading,
  });
export default configureReducers;
