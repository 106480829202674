/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form } from 'tabler-react';
import {
  ButtonGroup,
  Button,
  FormControl,
  IconButton,
  TextInput,
} from '@contentful/f36-components';
import { UsersIcon } from '@contentful/f36-icons';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import SiteWrapper from '../../../../wrappers/SiteWrapper';
import '../../../../styles/authenticated/providers-calendar.css';
import authActions from '../../../../redux/reducers/auth';

function WorkersCreateUpdate({
  location: {
    state: { create, provider },
  },
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <Formik
              initialValues={{
                email: '',
              }}
              validate={(values) => {
                const errors = {};
                if (values.email.length === 0) errors.price = 'Required';
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                  errors.email = 'Invalid email';
                }
                return errors;
              }}
              validateOnChange
              onSubmit={({ name, email }) => {
                dispatch(
                  authActions.workerSignup(name, email, provider.id, () =>
                    history.push(`/providers/profile/${provider.id}`),
                  ),
                );
              }}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Card>
                    <Card.Header>
                      <Card.Title>
                        {create
                          ? `Add an employee to the "${provider.name}" location`
                          : 'Editing worker'}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Grid.Row>
                        <Grid.Col sm={6} md={3} auto className="col-12">
                          <FormControl>
                            <FormControl.Label>Email*</FormControl.Label>
                            <TextInput
                              type="text"
                              placeholder="Worker's email"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              error={!!errors.email}
                              tick={!errors.email && values.email.length > 0}
                            />
                          </FormControl>
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                    <Card.Footer className="text-right">
                      <ButtonGroup variant="spaced" spacing="spacingM">
                        <Button
                          type="button"
                          outline
                          variant="secondary"
                          // className="mr-2"
                          onClick={() => window.history.back()}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="positive">
                          {create ? 'Add Team Member' : 'Update Team Member'}
                        </Button>
                      </ButtonGroup>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </SiteWrapper>
    </>
  );
}

export default withRouter(WorkersCreateUpdate);
