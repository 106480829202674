import { gql } from '@apollo/client';
import apolloClient from '../helpers/ApolloClient';

class User {
  static getInfo(email) {
    return apolloClient.query({
      variables: { email },
      query: gql`
        query GetUserInfo($email: String) {
          user(where: { email: { _eq: $email } }) {
            id
            email
            role
            profile {
              id
              name
              lastname
              phone_number
              address
              address_2
              city
              state
              zip
              picture
              verified_location
            }
          }
        }
      `,
    });
  }

  static getRole(email) {
    return apolloClient.query({
      variables: { email },
      query: gql`
        query GetUserInfo($email: String) {
          user(where: { email: { _eq: $email } }) {
            role
          }
        }
      `,
    });
  }

  static getHereApiKey = () =>
    apolloClient.query({
      query: gql`
        query GetHereApiKey {
          config_dictionary_by_pk(key: "HERE_API_KEY") {
            key
            value
          }
        }
      `,
    });

  static insertProfile(data) {
    // console.log('insertProfile');
    // console.log({ ...data });
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation InsertProfile(
          $firstname: String!
          $lastname: String!
          $phone: String!
          $userId: uuid!
        ) {
          insert_profile_one(
            object: {
              name: $firstname
              lastname: $lastname
              phone_number: $phone
              user_id: $userId
            }
          ) {
            name
          }
        }
      `,
    });
  }

  static updateProfileByPk(data) {
    return apolloClient.mutate({
      variables: { ...data },
      mutation: gql`
        mutation UpdateProfile(
          $id: uuid!
          $firstname: String!
          $lastname: String!
          $phone: String!
        ) {
          update_profile_by_pk(
            _set: { name: $firstname, lastname: $lastname, phone_number: $phone }
            pk_columns: { id: $id }
          ) {
            name
          }
        }
      `,
    });
  }
}

export default User;
