/* eslint-disable no-confusing-arrow */
import { createReducer, createActions } from 'reduxsauce';

const INITIAL_STATE = {
  name: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  picture: '',
  hereApiKey: '',
};

const { Types, Creators } = createActions({
  initProfile: ['username'],
  setProfile: ['payload'],
  getHereApiKey: null,
  setHereApiKey: ['payload'],
});

const setProfile = (state, { payload }) => (payload ? { ...state, ...payload } : INITIAL_STATE);

const setHereApiKey = (state, { payload }) =>
  payload
    ? {
        ...state,
        ...payload,
      }
    : INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PROFILE]: setProfile,
  [Types.SET_HERE_API_KEY]: setHereApiKey,
});

export const ProfileTypes = Types;
export default Creators;
