import React from 'react';
import { withRouter } from 'react-router-dom';
import { Page } from 'tabler-react';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import ReportTable from './components/ReportTable';

function ProvidersReport() {
  document.title = 'Manage Providers - Washn Admin';
  return (
    <SiteWrapper>
      <Page.Content title="Provider Reports">
        <ReportTable />
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersReport);
