/* eslint-disable no-unused-vars, no-undef */
import React, { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form, Icon } from 'tabler-react';
import {
  IconButton,
  Button,
  Stack,
  ButtonGroup,
  TextLink,
  Notification,
  TextInput,
  FormControl,
} from '@contentful/f36-components';
import { PreviewIcon, PreviewOffIcon, PlusIcon, ChevronDownIcon } from '@contentful/f36-icons';
import { Formik } from 'formik';
import authActions from '../../redux/reducers/auth';
import * as styles from '../../styles/not-authenticated/notauth.module.css';
import StandaloneFormPage from '../../helpers/StandaloneFormPage';
import ForgotPasswordDialog from './components/ForgotPasswordDialog';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [isFirstLocked, setFirstLocked] = useState(true);
  const [open, setOpen] = useState(false);

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleSubmitForgotPassword = (value) => {
    dispatch(
      authActions.forgotPassword(value, () => history.push('reset-password', { email: value })),
    );
  };

  const handleSubmitCode = (formData) => {
    dispatch(authActions.confirmForgotPassword(formData, () => setOpen(!open)));
  };

  return (
    <StandaloneFormPage>
      <ForgotPasswordDialog
        isShown={open}
        handleClose={handleDialog}
        handleSubmit={handleSubmitForgotPassword}
        handleSubmitCode={handleSubmitCode}
      />
      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values) => {
            dispatch(authActions.signIn(values.email, values.password));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form.Group className={styles.inputsContainer}>
              <FormControl isRequired>
                <FormControl.Label>Washn Staging Login</FormControl.Label>
                <FormControl.Label>Email</FormControl.Label>
                <TextInput
                  placeholder="Email"
                  type="email"
                  value={values.email}
                  tick={!errors.email && values.email}
                  onChange={({ target: { value } }) => {
                    setFieldValue('email', value.toLowerCase(), true);
                  }}
                  name="email"
                  error={errors.email ? errors.email : null}
                />
                <FormControl.Label>Password</FormControl.Label>
                <Form.InputGroup className={styles.passwordContainer}>
                  <TextInput.Group>
                    <TextInput
                      // className="mt-3"
                      id="password"
                      placeholder="Password"
                      tick={!errors.password && values.password}
                      value={values.password}
                      onChange={handleChange}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                    />
                    <IconButton
                      variant="secondary"
                      icon={<PreviewIcon />}
                      onClick={() => setShowPassword(!showPassword)}
                      type="button"
                      className={styles.passwordInput}
                    />
                  </TextInput.Group>
                </Form.InputGroup>
              </FormControl>
              <Button
                variant="secondary "
                onClick={handleSubmit}
                type="submit"
                isDisabled={!!errors.email}
                isFullWidth
              >
                Login
              </Button>
            </Form.Group>
          )}
        </Formik>
        <Stack flexDirection="row" spacing="spacingS">
          <TextLink variant="secondary" onClick={handleDialog}>
            Forgot Password
          </TextLink>
          <TextLink
            variant="secondary"
            onClick={() => {
              window.location.href = '/account-confirmation';
            }}
          >
            Confirm Account
          </TextLink>
        </Stack>
      </div>
    </StandaloneFormPage>
  );
}

export default withRouter(Login);
