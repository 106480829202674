import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Stack } from '@contentful/f36-components';
import { Button, Page, Grid } from 'tabler-react';
import SiteWrapper from '../../../wrappers/SiteWrapper';
import styles from '../../../styles/authenticated/providers.module.css';
import ProvidersTable from './components/ProvidersTable';

function ProvidersHome() {
  const history = useHistory();
  const { role } = useSelector((state) => state.auth.user);
  document.title = 'Manage Providers - Washn Admin';
  return (
    <SiteWrapper>
      <Page.Content>
        <Grid.Row cards>
          <Grid.Col sm={6} lg={12}>
            <Stack flexDirection="column" marginBottom="spacingM" spacing="spacingS">
              {role === 'provider' && (
                <>
                  <Button
                    style={{ padding: 20 }}
                    type="button"
                    icon="map-pin"
                    color="primary"
                    className={styles.providerButtons}
                    outline
                    onClick={() => history.push('/providers/create')}
                  >
                    Add A location
                  </Button>
                </>
              )}
            </Stack>
          </Grid.Col>
        </Grid.Row>
        <ProvidersTable />
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(ProvidersHome);
