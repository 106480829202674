import { gql } from '@apollo/client';
import apolloClient from '../helpers/ApolloClient';

class Admin {
  static updateProviderByPk(props, id) {
    return apolloClient.mutate({
      variables: { id, params: props },
      mutation: gql`
        mutation UpdateProvider($id: uuid!, $params: provider_set_input) {
          update_provider_by_pk(_set: $params, pk_columns: { id: $id }) {
            name
          }
        }
      `,
    });
  }
}

export default Admin;
