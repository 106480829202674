/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
export default function getParams(str) {
  let queryString = str || window.location.search || '';
  let keyValPairs = [];
  const params = {};
  queryString = queryString.replace(/.*?\?/, '');

  if (queryString.length) {
    keyValPairs = queryString.split('&');
    for (const pairNum in keyValPairs) {
      const key = keyValPairs[pairNum].split('=')[0];
      if (!key.length) continue;
      if (typeof params[key] === 'undefined') params[key] = [];
      params[key].push(keyValPairs[pairNum].split('=')[1]);
    }
  }
  return params;
}
