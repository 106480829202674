import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form } from 'tabler-react';
// import moment from 'moment';
import providersActions from '../../../../redux/reducers/providers';
import styles from '../../../../styles/authenticated/providers.module.css';

export default function VehicleEditor({ membershipOrder }) {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.providers.customerOrders);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedVehicleId, setSelectedVehicleId] = useState(membershipOrder.vehicleId);
  const [editionMode, setEditionMode] = useState(false);

  useEffect(() => {
    const putSelectedOneFirstAndRemoveVehiclesWithActiveMemberships = (array) => {
      const selected = array.find((v) => v.id === selectedVehicleId);
      const arrayWithoutSelected = array.filter((veh) => veh.id !== selectedVehicleId);
      const withoutVehiclesWithActiveMemberships = [];

      arrayWithoutSelected.forEach((v) => {
        if (!v.membership_order_detail.some((od) => od.order?.status === 'ACTIVE_MEMBERSHIP')) {
          withoutVehiclesWithActiveMemberships.push(v);
        }
      });

      withoutVehiclesWithActiveMemberships.unshift(selected);

      return withoutVehiclesWithActiveMemberships;
    };

    if (orders.length > 0) {
      const allUserVehicles = putSelectedOneFirstAndRemoveVehiclesWithActiveMemberships(
        orders[0].user.vehicles,
      );

      // console.log('VEHI', orders[0].user.vehicles);
      // const baddate =
      const vehiclesWithDescription = allUserVehicles.map((v) => ({
        ...v,
        description: `${v.color} ${v.maker.name} ${v.model.name} - ${v.license} ${v.deleted_at}`,
      }));
      console.log('VEHI', vehiclesWithDescription);
      setVehicles(vehiclesWithDescription);
    }
  }, [orders]);

  useEffect(() => {
    if (vehicles.length > 0) {
      setSelectedVehicle(vehicles.find((v) => v.id === selectedVehicleId));
    }
  }, [vehicles, selectedVehicleId]);

  const save = () => {
    dispatch(
      providersActions.replaceMembershipVehicle(
        membershipOrder.customerUuid,
        membershipOrder.id,
        selectedVehicleId,
      ),
    );
    setEditionMode(false);
  };

  const cancelEdition = () => {
    setEditionMode(false);
  };

  if (vehicles.length === 0) return null;
  return !editionMode ? (
    <>
      <span>{selectedVehicle?.description}</span>
      <Button size="sm" color="primary" className="ml-4" onClick={() => setEditionMode(true)}>
        Edit
      </Button>
    </>
  ) : (
    <span>
      <Form.Select
        className={styles.vehicleSelect}
        onChange={(e) => {
          setSelectedVehicleId(e.target.value);
        }}
      >
        {vehicles.map((v) => (
          <option key={v.id} value={v.id}>
            {v.description}
          </option>
        ))}
      </Form.Select>
      <Button size="sm" color="success" className="ml-4" onClick={save}>
        Save
      </Button>
      <Button size="sm" color="danger" className="ml-4" onClick={cancelEdition}>
        Cancel
      </Button>
    </span>
  );
}
