/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';
import { call, put, takeEvery, getContext } from 'redux-saga/effects';
import authCreators from '../reducers/auth';
import alertActions from '../reducers/alert';
import loadingActions from '../reducers/loading';
import profileCreators, { ProfileTypes } from '../reducers/profile';
import safe from './sagaErrorHandling';

function* attachInitProfile() {
  yield takeEvery(ProfileTypes.INIT_PROFILE, safe(initProfile));
}
function* attachGetHereApiKey() {
  yield takeEvery(ProfileTypes.GET_HERE_API_KEY, safe(getHereApiKey));
}

function* initProfile() {
  try {
    const userLogged = yield Auth.currentAuthenticatedUser();
    const userApi = yield getContext('userApi');
    const userResponse = yield call(userApi.getInfo, userLogged?.username);
    const { email, role, profile, id } = userResponse.data.user[0];
    let picture;
    try {
      picture = JSON.parse(profile.picture);
    } catch {
      picture = '';
    }
    if (profile) {
      const { __typename, phone_number, address_2, zip, ...profileData } = profile;
      yield put(
        profileCreators.setProfile({
          ...profileData,
          address2: address_2,
          phone: phone_number || '',
          picture,
          zip: `${zip}`,
        }),
      );
    }
    if (email && role) {
      yield put(authCreators.setUser({ user: { id, email, role }, authenticated: true }));
    }
  } catch (err) {
    yield put(authCreators.setUser({ authenticated: false }));
    if (err === 'The user is not authenticated') return;
    if (err?.message) {
      yield put(
        alertActions.displayAlert({
          message: err.message,
          type: 'error',
        }),
      );
    } else {
      throw err;
    }
  } finally {
    yield put(loadingActions.setLoading(false));
  }
}

function* getHereApiKey() {
  try {
    const userApi = yield getContext('userApi');
    const {
      data: {
        config_dictionary_by_pk: { value },
      },
    } = yield call(userApi.getHereApiKey);
    yield put(profileCreators.setHereApiKey({ hereApiKey: value }));
  } catch (err) {
    if (err?.message) {
      console.log(err);
    } else {
      throw err;
    }
  }
}

export default [attachInitProfile, attachGetHereApiKey];
