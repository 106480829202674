/* eslint-disable camelcase, no-unused-vars, react/no-unescaped-entities, prettier/prettier, yoda */
import React, { useEffect, useState, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Container, Grid, Card, Form } from 'tabler-react';
import {
  ButtonGroup,
  Button,
  // FormControl,
  // IconButton,
  TextInput,
  FormControl,
} from '@contentful/f36-components';
import { Formik } from 'formik';

import Gallery from 'react-photo-gallery';
import SelectedImage from '../components/SelectedImage';
// import photos from '../../../../assets/photos';

import SiteWrapper from '../../../../wrappers/SiteWrapper';
import providersActions from '../../../../redux/reducers/providers';

function AddonsCreateUpdate({
  location: {
    state: { create, provider },
  },
  match: { params },
}) {
  const [toEdit, setToEdit] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [imagePreview, setImagePreview] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectable] = useState(1);
  const [selectedImage, setSelectedImage] = useState(-1);
  const images = useSelector((state) => state.providers.userImages);
  const [imagesList, setImages] = useState(images);

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        selectable={selectable}
        selectAll={selectedImage === index && photo.selected}
        key={key}
        margin={25}
        right
        index={index}
        photo={photo}
        left={left}
        top={top}
        onChangeImage={handleImageChange}
      />
    ),
    [imagesList],
  );
  // console.log(selectedImage);

  // const handleImageChange = (event) => {
  //   setImage(event.currentTarget.files[0]);
  //   setImagePreview(URL.createObjectURL(event.currentTarget.files[0]));
  // };

  const handleImageChange = (photo) => {
    const tempImages = images.map((i, index) => {
      if (i.id === photo.id) {
        setImage(photo.src);
        setSelectedImage(index);
        i.selected = !i.selected;
        return i;
      }
      i.selected = false;
      return i;
    });

    setImages(tempImages);
  };

  // console.log('PREVIEW', params.uuid);

  if (!create && !params.uuid) return null;

  useEffect(() => {
    if (provider.name && !create) {
      setToEdit(provider.addons.find((addon) => addon.id === params.uuid));
    }
  }, [params.uuid]);

  useEffect(() => {
    dispatch(providersActions.getImages());
  }, [images]);

  if (!create && !toEdit) return null;

  if (!create && toEdit.image && !imagePreview) {
    try {
      const { uri, key } = JSON.parse(toEdit.image);
      if (uri) setImagePreview(uri);
      else if (key) {
        Storage.get(key, { level: 'public' }).then((data) => setImagePreview(data));
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <SiteWrapper>
      <div className="my-3 my-md-5">
        <Container>
          <Formik
            initialValues={{
              name: toEdit?.name || '',
              price: toEdit?.price || '',
            }}
            validate={(values) => {
              const errors = {};
              if (values.name.length === 0) errors.name = 'Required';
              if (values.price.length === 0) errors.price = 'Required';
              return errors;
            }}
            validateOnChange
            validateOnMount={!create}
            onSubmit={(values) => {
              if (create) {
                return dispatch(
                  providersActions.insertAddon(
                    {
                      ...values,
                      image,
                      provider_id: provider.id,
                    },
                    history.push(`/providers/profile/${provider.id}`),
                  ),
                );
              }
              return dispatch(
                providersActions.updateAddonByPk(
                  {
                    ...values,
                    image,
                    id: toEdit.id,
                    provider_id: provider.id,
                    oldImageKey: toEdit.image,
                  },
                  history.push(`/providers/profile/${provider.id}`),
                ),
              );
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <Form className="card" onSubmit={handleSubmit}>
                <Card.Header>
                  <Card.Title>
                    {create
                      ? `Create Addons for provider location "${provider.name}"`
                      : `Editing Addon "${toEdit.name}"`}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col md={5} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Name</FormControl.Label>
                        <TextInput
                          type="text"
                          placeholder="Addon Name"
                          name="name"
                          onChange={handleChange}
                          value={values.name}
                          error={!!errors.name}
                          tick={!errors.name && values.name.length > 0}
                        />
                      </FormControl>
                    </Grid.Col>
                    <Grid.Col md={3} auto className="col-12">
                      <FormControl>
                        <FormControl.Label>Price</FormControl.Label>
                        <TextInput.Group>
                          <Form.InputGroupPrepend>
                            <Button isDisabled RootComponent="span" color="primary" outline>
                              $
                            </Button>
                          </Form.InputGroupPrepend>
                          <TextInput
                            type="number"
                            placeholder="Price"
                            name="price"
                            onChange={handleChange}
                            value={values.price}
                            error={!!errors.price}
                            tick={!errors.price && values.price.length > 0}
                          />
                        </TextInput.Group>
                      </FormControl>
                    </Grid.Col>
                      <Grid.Col md={2} auto className="col-6">
                        <Form.Group label="Active Image">
                          {imagePreview && (
                            <>
                              <Card style={{ maxWidth: '100%' }}>
                                <img
                                  style={{
                                    maxWidth: 90,
                                    maxHeight: '90%',
                                    // marginTop: '1rem',
                                    // marginLeft: '1rem',
                                    resizeMode: 'contain',
                                  }}
                                  src={imagePreview}
                                  alt="Uploaded"
                                />
                              </Card>
                            </>
                          )}
                        </Form.Group>
                      </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col md={12} auto className="col-12 mb-3">
                      {/* <Button
                        type="button"
                        outline
                        color="primary"
                        className="mb-3"
                        onClick={() => toggleSelectAll()}
                      >
                        select all
                      </Button> */}
                      <Gallery photos={images} renderImage={imageRenderer} className="row" />
                    </Grid.Col>
                  </Grid.Row>
                  {/**
                  <Grid.Row>
                    <Grid.Col md={3} auto className="col-12" style={{ maxWidth: '50%' }}>
                      <Form.Group label="Upload Image">
                        <Form.FileInput
                          name="image"
                          // onChange={handleImageChange}
                          accept="image/*"
                        />
                        {imagePreview && (
                          <>
                            <Card style={{ maxWidth: '50%' }} title="Uploaded Image">
                              <img
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  marginTop: '1rem',
                                  marginLeft: '1rem',
                                }}
                                src={imagePreview}
                                alt="Uploaded"
                              />
                            </Card>
                          </>
                        )}
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                */}
                </Card.Body>
                <Card.Footer className="text-right">
                  <ButtonGroup variant="spaced" spacing="spacingM">
                    <Button
                      variant="secondary"
                      // type="button"
                      // outline
                      // color="primary"
                      className="mr-2"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </Button>
                    {create ?
                      <Button
                        isDisabled={selectedImage === -1}
                        type="submit"
                        variant="positive">
                          Create Addon
                        </Button>
                      :
                      <Button
                        isDisabled={selectedImage === -1}
                        type="submit"
                        variant="positive">
                          Update Addon
                      </Button>
                    }
                  </ButtonGroup>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
    </SiteWrapper>
  );
}

export default withRouter(AddonsCreateUpdate);
