import { put, fork, takeEvery, cancelled, take, cancel } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import dashboardCreators, { DashboardTypes } from '../reducers/dashboard';
import safe from './sagaErrorHandling';
import apolloSubClient from '../../helpers/ApolloClient';
import Dashboard from '../../libs/Dashboard';
import alertActions from '../reducers/alert';

function* attachDashboardSubscription() {
  yield takeEvery(DashboardTypes.DASHBOARD_SUB_START, safe(subscribeSessionHandler));
}

function* subscribeSessionHandler() {
  const bgSyncTask = yield fork(subscribeSession);
  yield take(DashboardTypes.DASHBOARD_SUB_END);
  console.log('cancelled');
  yield cancel(bgSyncTask);
}

function* subscribeSession() {
  try {
    const channel = eventChannel(
      (emitter) =>
        apolloSubClient
          .subscribe({
            query: Dashboard.ordersAndWorkersSubscription,
          })
          .subscribe(
            (item) => {
              emitter(item);
            },
            (err) => console.log(err),
          ).unsubscribe,
    );

    if (yield cancelled()) {
      try {
        console.log('closing channel');
        channel.close();
      } catch (error) {
        console.log('cancel task error', error);
      }
    }

    while (true) {
      const { data } = yield take(channel);
      console.log('channel data', data);
      const { workers, orders } = data.provider[1];
      if (workers) {
        yield put(dashboardCreators.setWorkers(workers));
      }
      if (orders) {
        yield put(dashboardCreators.setRelevantOrders(orders));
      }
    }
  } catch (err) {
    yield put(alertActions.displayAlert({ message: err.message, type: 'error' }));
  }
}

export default [attachDashboardSubscription];
