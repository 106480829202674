import { gql } from '@apollo/client';

class Dashboard {
  static ordersAndWorkersSubscription = gql`
    subscription MySubscription($id: uuid) {
      provider(where: { id: { _eq: $id } }) {
        id
        name
        orders {
          id
          status
          number
          created_at
          washn_code
          checkin_at
          total
          deal
          details {
            service_id
            addon_id
            price
          }
        }
        workers {
          user {
            last_login
            role
          }
        }
      }
    }
  `;
}

export default Dashboard;
