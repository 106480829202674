import Skeleton from '@material-ui/lab/Skeleton';
import { Storage } from 'aws-amplify';
import React, { useState, useEffect } from 'react';

const WashnImage = ({ src, style, alt, skeletonHeigth, level = 'public' }) => {
  const [image, setImage] = useState(null);

  async function getImageFromS3(json) {
    try {
      const { key, uri, id } = JSON.parse(json);
      if (uri) setImage(uri);
      const response = await Storage.get(key, {
        level,
        identityId: id,
      });
      setImage(response);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (src) getImageFromS3(src);
  }, [src]);

  if (!image) {
    return <Skeleton variant="rect" width="100%" height={skeletonHeigth} />;
  }
  return <img style={{ ...style }} src={image} alt={alt} />;
};

export default React.memo(WashnImage);
